import { Container, Row, Col } from "react-bootstrap";
import { showMoney } from "./Data/Money";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Button } from "./Controls/Button";
import { Plan } from "./Data/ApiTypes";
import { PageBar } from "./Controls/PageBar";

export function FacilityPlanListPage() {
    const nav = useNavigate();
    const plans = useLoaderData() as Plan[];

    return <Container>
        <Row className="justify-content-center">
            <Col md="8" lg="8" xl="5">
                <PageBar title="Monthly Plans" />
                {plans.map( plan =>
                    <Button key={plan.planId} onClick={() => nav( `${plan.planId}` )}
                            className="w-100 text-start border border-2 border-primary my-3 rounded-4 p-3"
                         style={{ display: "grid", gridTemplateColumns: "max-content 1fr max-content"}}>
                        <div className="h5 fw-bold grid-col-1">
                            {plan.name}
                        </div>
                        <div className="fs-5 fw-bold grid-col-3">
                            {plan.planPrice ? showMoney( plan.planPrice.price ) : "Not Listed"}
                        </div>
                        <div className="text-secondary">
                            {plan.accessHours}
                        </div>
                    </Button>)}
                <Button className="w-100" onClick={ () => nav( "create" ) }>
                    Create New Monthly Plan
                </Button>
            </Col>
        </Row>
    </Container>;
}