import { Container, Row, Col, Card, ListGroupItem, ListGroup } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { Button } from "./Controls/Button";
import { useState } from "react";
import { AddLineItemModal, LineItem } from "./FacilityInvoiceCreatePageAddLineItemModal";
import { AddRecipientModal, RecipientForm } from "./FacilityInvoiceCreatePageAddRecipientModal";
import { showAddressHtml } from "./Data/Location";
import React from "react";
import { Input } from "./Controls/Input";
import { OperatorApi } from "./Data/ApiTransport";
import { useRouteLoaderData } from "react-router-dom";
import { Facility } from "./Data/ApiTypes";
import { PersonAdd } from "react-bootstrap-icons";

export function FacilityInvoiceCreatePage() {    
    const facility = useRouteLoaderData( "facility" ) as Facility;

    const operator: OperatorApi = {
        operatorId: 0,
        name: "",
        address: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: ""
        },
        emailAddress: "",
        phoneNumber: "",
        website: "",
        topTitle: "",
        topText: "",
        bottomLeftTitle: "",
        bottomLeftText: "",
        bottomRightTitle: "",
        bottomRightText: ""
    };
    const [showAddRec, setShowAddRec  ] = useState( false );
    const [showAddItem, setShowAddItem] = useState( false );
    const [recipient, setRecipients] = useState<RecipientForm>();
    const [items, setItems] = useState<LineItem[]>( [] );

    function addRecipient( r: RecipientForm ) {
        setRecipients( r );
    }

    function addItem( item: LineItem ) {
        setItems( [ ...items, item ] );
    }

    return <Container fluid>
        <AddRecipientModal show={showAddRec}  setShow={setShowAddRec}  onSave={addRecipient} />
        <AddLineItemModal  show={showAddItem} setShow={setShowAddItem} onSave={addItem} />
        <Row>
            <Col>
                <div className="d-flex align-items-center gap-2 mb-1">
                    <PageTitle className="text-nowrap">
                        Custom Order
                    </PageTitle>
                    <Button variant="sm"
                            className="py-0 px-3 d-flex-align-center"
                            onClick={ () => setShowAddRec( true )}>
                                <PersonAdd color="maroon" /> Set Recipient
                    </Button>
                    <Button variant="sm"
                            className="py-0 px-3 d-flex-align-center"
                            onClick={ () => setShowAddItem( true )}>
                                <PersonAdd color="maroon" /> Add Item
                    </Button>
                </div>
                <ListGroup className="text-muted">
                    <ListGroupItem>Use this form to create a custom order for a customer</ListGroupItem>
                    <ListGroupItem>This will create an account for the recipient if one does not exist.</ListGroupItem>
                </ListGroup>
                <div className="p-3 my-3 shadow">
                    <pre>
                        <strong>{facility.name}</strong>
                        {showAddressHtml( facility.address )}
                    </pre>
                    <div className="d-flex align-items-center">
                        <div>
                            To: &nbsp;
                            {recipient === undefined ? "No Recipient Added" : <>{recipient.firstName} {recipient.lastName}</>}
                        </div>
                    </div>
                    <Container fluid className="g-0">
                        <Row>
                            <Col xs="12" xl="6">
                                <div className="d-grid mt-2">
                                    <div className="grid-col-1 border-bottom border-2">Product or Service</div>
                                    <div className="grid-col-2 border-bottom border-2">Category</div>
                                    <div className="grid-col-3 border-bottom border-2 text-end">Unit Price</div>
                                    <div className="grid-col-4 border-bottom border-2 text-end">Quantity</div>
                                    {items.length === 0 && "No Items added yet"}
                                    {items.map( (el, i) => <React.Fragment key={i}>
                                        <div className="grid-col-1">{el.desc}</div>
                                        <div className="grid-col-2">{el.cat}</div>
                                        <div className="grid-col-3 text-end">{el.unitPrice}</div>
                                        <div className="grid-col-4 text-end">{el.qty}</div>
                                    </React.Fragment>)}
                                </div>
                            </Col>
                            <Col xs="12" xl="6">
                                <div className="mt-2">
                                    <Input type="text" as="textarea" label="Notes" rows={7} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Card.Footer className="d-flex gap-2">
                    <Button className="flex-grow-1">Discard Invoice</Button>
                    <Button className="flex-grow-1">Send Invoice</Button>
                </Card.Footer>
            </Col>
        </Row>
    </Container>
}