import { useMemo, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { cents, Money, showMoney } from "./Data/Money";
import { useParamIds } from "./Data/Common";
import { ColDef, GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { useLoaderData, useNavigate } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { FacilitySub, ParkerDetails, ParkerListEntry, Reservation, SubListEntry, Vehicle } from "./Data/ApiTypes";
import { SubscriptionStatus } from "./Data/ApiTransport";
import { showSubscriptionStatus } from "./Data/Finance";
import { Button } from "./Controls/Button";
import { Asterisk } from "react-bootstrap-icons";
import { colMoney, colNumber, DataGrid } from "./Controls/DataGrid";
import { add, ComparisonOperator, Dinero, DineroOptions, DineroSnapshot, multiply } from "dinero.js";

enum MonthlyParkerTab {
    Applied = "pending",
    Active  = "active",
    Invited = "invited"
}

export interface GridParker {
    parkerId:      number;
    name:          string;
    note:          string;
    emailAddress:  string;
    phoneNumber:   string | null;
    spots:         number;
    balance:       Money;
    mrr:           Money;
    subscriptions: SubListEntry[];
    reservations:  Reservation[];
    vehicles:      Vehicle[];
}

export function FacilityParkerListPage() {
    const nav = useNavigate();
    const { facilityId }      = useParamIds();
    const [ tab,     setTab ] = useState<MonthlyParkerTab>( MonthlyParkerTab.Active );
    const [ parkers, subs   ] = useLoaderData() as [ParkerDetails[], FacilitySub[]];

    const spotCount = ( p: ParkerDetails ) =>
        p.subscriptions.map( s => s.quantity ).reduce( ( a, b ) => a + b, 0 );

    const mrr = ( p: ParkerDetails ) =>
        p.subscriptions.map( s => multiply( cents( s.unitPrice! ), s.quantity ) ).reduce( (a, b) => add( a, b ), cents( 0 ) );

    const activeParkers = useMemo( () => {
        const innerParkers: GridParker[] = [];
        for( const parker of parkers ) {
            const p: GridParker = {
                ...parker,
                spots:   spotCount( parker ),
                balance: parker.balance,
                mrr:     mrr( parker ),
            }
            innerParkers.push( p );
        }
        return innerParkers;
    }, [parkers] );

    const activeColumns = useMemo<ColDef<GridParker>[]>(() => [
        { field: "parkerId", headerName: "Id"},
        { field: "name",     headerName: "Name"},
        // { field: "emailAddress", headerName: "Email"},
        // { valueGetter: x => x.data?.subscriptions.length, headerName: "Plans" },
        colNumber( "spots"  ),
        colMoney( "balance" ),
        { ...colMoney( "mrr" ), headerName: "MRR" },
        { field: "note", headerName: "Note" },
    ], []);

    const appliedColumns = useMemo<ColDef<FacilitySub>[]>(() => [
        { field: "parker.name", headerName: "Name" },
        { field: "quantity", headerName: "Spots" },
        { field: "status", headerName: "Status", valueFormatter: x => showSubscriptionStatus( x.value ) },
        {
            field: "unitPrice",
            headerName: "Unit Price",
            type: "rightAligned",
            valueFormatter: x => showMoney( x.value )
        },
        {
            field: "price",
            headerName: "Total Price",
            type: "rightAligned",
            valueFormatter: x => showMoney( x.value )
        },
        {
            field: "plan.name",
            headerName: "Plan",
            valueGetter: (params) => params.data?.plan?.name || ''
        },
    ], []);

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: any ) => {
        if( tab === MonthlyParkerTab.Active ) {
            const data: ParkerListEntry = event.data;
            nav( `/facility/${facilityId}/parker/${event.data.parkerId}` );
        } else if( tab === MonthlyParkerTab.Applied ) {
            const data: FacilitySub = event.data;
            nav( `/facility/${facilityId}/parker/${data.parkerId}/plan/${data.subscriptionId}` );
        }
    };

    const applied = subs.filter( s => s.status == SubscriptionStatus.Applied            );
    const waiting = subs.filter( s => s.status == SubscriptionStatus.WaitingForContract );

    //footer
    const footer = useMemo( () => {
        const totalBal   = activeParkers.map( p => p.balance ).reduce( ( a, b ) => add( a, b ), cents( 0 ) );
        const totalSpots = activeParkers.map( spotCount ).reduce( ( a, b ) => a + b, 0 );
        const totalMrr   = activeParkers.map( mrr       ).reduce( ( a, b ) => add( a, b ), cents( 0 ) );

        const f = {
            spots:    totalSpots,
            balance:  totalBal,
            mrr:      totalMrr,
        }
        return [ f ];
    }, [activeParkers] );

    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100" style={{ display: "grid", gridTemplateRows: "auto auto 1fr 5px", gridAutoColumns: "1fr" }}>
                <div className="d-flex align-items-center gap-2">
                    <PageTitle>
                        Monthly Parkers
                    </PageTitle>
                    <Button variant="sm"
                        className="d-flex-align-center"
                        onClick={() => nav( `/facility/${facilityId}/parker/invite` )}>
                        <Asterisk color="maroon" /> Invite Parker
                    </Button>
                </div>
                <Tabs
                    defaultActiveKey={MonthlyParkerTab.Active}
                    activeKey={tab}
                    onSelect={( k ) => setTab( k as MonthlyParkerTab )}
                    className="w-100 border-bottom-0"
                    variant="underline"
                    justify>
                    <Tab eventKey={MonthlyParkerTab.Active} title="Active" className="h-100">
                        <div style={{ height: "100%", width: '100%' }}>
                            <DataGrid
                                rowData={activeParkers}
                                columnDefs={activeColumns}
                                onGridReady={onGridReady}
                                onRowClicked={onRowClicked}
                                pinnedBottomRowData={footer}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey={MonthlyParkerTab.Invited} title="Waiting for Contract" className="h-100">
                        <div style={{ height: "100%", width: '100%' }}>
                            <DataGrid
                                rowData={waiting}
                                columnDefs={appliedColumns}
                                onGridReady={onGridReady} />
                        </div>
                    </Tab>
                    <Tab eventKey={MonthlyParkerTab.Applied} title="Applied" className="h-100">
                        <div style={{ height: "100%", width: '100%' }}>
                            <DataGrid
                                rowData={applied}
                                columnDefs={appliedColumns}
                                onGridReady={onGridReady}
                                onRowClicked={onRowClicked} />
                        </div>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>;
}