import { ReactNode } from "react";
import { UsAddress } from "./ApiTypes";

export function showAddress( address: UsAddress ) {
    const { addressLine1: s1,
            addressLine2: s2,
            city:    c,
            state:   st,
            zipCode: z } = { ...address };
    return `${s1} ${s2} ${c}, ${st} ${z}`;
}

export function showAddressFunc( address: UsAddress, func: ( s: string, c: string, st: string, z: string ) => ReactNode ) {
    const {
        addressLine1: s,
        city:         c,
        state:        st,
        zipCode:      z } = { ...address };
    return func( s, c, st, z );
}

export function showAddressHtml( address: UsAddress ) {
    return showAddressFunc( address, ( s, c, st, z ) => <div>
        <div>{s}</div>
        <div>{c}, {st} {z}</div>
    </div> );
}