import React from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Controls/Button";
import { Input } from "./Controls/Input";
import { Parker, ParkerUpdateReq } from "./Data/ApiTypes";

interface ContactInfoModalProps {
    parker:  Parker;
    show:    boolean;
    setShow: ( show:        boolean         ) => void;
    onSave:  ( contactInfo: ParkerUpdateReq ) => void;
}

export function ContactModal( { parker, show, setShow, onSave }: ContactInfoModalProps ) {
    const { register, formState: { errors }, reset, handleSubmit } = useForm<ParkerUpdateReq>(
        { defaultValues: { ...parker } } ); // Assume we're editing the first parker for this example

    function submit( data: ParkerUpdateReq ) {
        onSave( data );
        setShow( false );
        reset();
    }

    return <Modal show={show} centered onEscapeKeyDown={() => setShow(false)}>
        <Form onSubmit={handleSubmit(submit)}>
            <Modal.Header className="fs-5">
                Edit Parker
            </Modal.Header>
            <Modal.Body>
                <Input
                    type="text"
                    label="Name"
                    explanation="The name of the parker"
                    {...register("name", { required: "Name is required" })}
                    error={errors.name} />
                <Input
                    type="email"
                    label="Email Address"
                    explanation="The email address the parker uses to log in"
                    {...register("emailAddress", {
                        required: "Email address is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address"
                        }
                    })}
                    error={errors.emailAddress} />
                <Input
                    type="tel"
                    label="Phone Number"
                    explanation="Example: 555-555-5555"
                    {...register("phoneNumber", {
                        pattern: {
                            value: /^\d{3}-\d{3}-\d{4}$/,
                            message: "Invalid phone number pattern. Include dashes 555-555-5555"
                        }
                    })}
                    error={errors.phoneNumber} />

                <Input
                    type="text"
                    as="textarea"
                    rows={2}
                    label="Note"
                    {...register("note" )}
                    error={errors.note} />
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <Button className="flex-grow-1" onClick={() => setShow(false)}>Cancel</Button>
                <Button className="flex-grow-1" type="submit">Save</Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}