import React, { useState } from 'react';
import { Form } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { Select } from "./Controls/Select";
import { useForm } from "react-hook-form";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";
import { SmallPage } from "./Controls/SmallPage";
import { useNavigate } from "react-router-dom";
import { Api } from "./Data/Api";
import { InviteStaffReq } from "./Data/ApiTypes";
import { StaffRole } from "./Data/ApiTransport";

export function StaffInvitePage() {
    const nav = useNavigate();
    const [msg, setMsg] = useState<string>();

    const { register, handleSubmit, formState: { errors } } = useForm<InviteStaffReq>( {
        defaultValues: {
            name:         "",
            emailAddress: "",
            password:     "",
            note:         "",
            role:         StaffRole.OperatorAdmin
        }
    } );

    const onSubmit = async ( data: InviteStaffReq ) => {
        const res = await Api.invite( data );
        if( res.isOk ) {
            alert( "Added Staff!" );
            return;
        }
        setMsg( res.error );
    };

    return (
        <SmallPage>
            <PageTitle>Invite Staff Member</PageTitle>
            <Form onSubmit={handleSubmit( onSubmit )}>
                <Input
                    type="text"
                    label="Staff Name"
                    {...register( "name", { required: "Name is required" } )}
                    error={errors.name}
                />

                <Input
                    type="email"
                    label="Email"
                    {...register( "emailAddress", {
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address"
                        }
                    } )}
                    error={errors.emailAddress}
                />

                <Input
                    type="password"
                    label="Password"
                    {...register( "password", {
                        required: "Password is required",
                    } )}
                    error={errors.password}
                />

                <Select
                    disabled
                    label="Role"
                    {...register( "role", { required: "Role is required" } )}
                    error={errors.role}>
                    <option value={StaffRole.OperatorAdmin}>Operator Admin</option>
                </Select>

                <Input
                    type="text"
                    label="Note"
                    {...register( "note", { required: "Note is required" } )}
                    error={errors.note}
                />
                {msg && <div className="alert alert-danger">{msg}</div>}
                <Button type="submit">Send Staff Invitation</Button>
            </Form>
        </SmallPage>
    );
}