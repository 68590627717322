import { atomWithStorage } from "jotai/utils"
import { EventInput } from "@fullcalendar/core";
import { SignUpResp } from "./Api";
import { atom } from "jotai";

export enum SearchActivity {
    Hourly  = "hourly",
    Monthly = "monthly"
}

export interface MonthlyForm {
    location:  string;
    startDate: string;
}

export interface SpotSyncAuth {
    isLoggedIn:  boolean;
    account?:    SignUpResp;
}

export const authAtom     = atomWithStorage<SpotSyncAuth>( "auth", { isLoggedIn: false }, undefined, { getOnInit: true } );
export const scheduleAtom = atomWithStorage<EventInput[]>( "schedule", [], undefined, { getOnInit: true } );
export const collapseAtom = atom<boolean>( false );