import { cloneElement, ReactNode } from "react";
import { PageTitle } from "./PageTitle";
import { Button } from "./Button";
import { useNavigate } from "react-router-dom";
import { Icon, IconProps } from "react-bootstrap-icons";

interface PageBarProps {
    title:     string;
    children?: ReactNode;
}

export function PageBar({ title, children }: PageBarProps) {
    return (
        <div className="d-flex align-items-center gap-2">
            <PageTitle>
                {title}
            </PageTitle>
            {children}
        </div>
    );
}

interface ToolButtonProps {
    onClick:   () => void;
    children:  React.ReactNode;
}

export function ToolButton( { onClick, children }: ToolButtonProps ) {
    return <Button variant="sm"
                   className="d-flex-align-center"
                   onClick={onClick}>
        {children}
    </Button>
}