import { useMemo, useState } from 'react';
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';

import { useLoaderData, useNavigate } from "react-router-dom";
import { Payment } from "./Data/ApiTypes";
import { showMoney } from "./Data/Money";
import { showPaymentType } from "./Data/Finance";
import { PageTitle } from "./Controls/PageTitle";
import { CheckFormModal } from "./FacilityParkerPaymentListPageAddCheckModal";
import { useParamIds } from "./Data/Common";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { parsePayment } from "./Data/ApiParse";
import { Button } from "./Controls/Button";
import { CurrencyDollar } from "react-bootstrap-icons";
import { colInstantDate, DataGrid } from "./Controls/DataGrid";

export function FacilityParkerPaymentListPage() {
    const nav = useNavigate();
    const { facilityId, parkerId } = useParamIds();
    const loadedPayments = useLoaderData() as Payment[];

    //state
    const [ showAddCheck, setShowAddCheck ] = useState( false );
    const [ payments,     setPayments     ] = useState( loadedPayments );

    const desc = ( src: ValueFormatterParams<Payment, any> ): string => {
        const apps = src.data!.applications;
        if( apps.length === 0 ) { return "Payment Not Applied";       }
        if( apps.length === 1 ) { return `Order #${apps[0].orderId}`; }
        return `Multiple Orders`;
    }

    const paymentColumns = useMemo<ColDef<Payment>[]>( () => [
        { field: 'paymentId',    headerName: 'Payment #',    type: 'rightAligned' },
        { field: 'type',         headerName: 'Type',            valueFormatter: x => showPaymentType( x.value ) },
        { field: 'applications', headerName: "Orders Affected", valueFormatter: desc  },
        { field: 'amount',       headerName: 'Amount',       type: 'rightAligned', valueFormatter: x => showMoney( x.value ) },
        { ...colInstantDate( "createdWhen", x => x.facility.timeZone ), headerName: "Created Date" },
    ], [] );

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: any ) => {
        const pmt: Payment = event.data;
        nav( `/facility/${pmt.facilityId}/parker/${pmt.parkerId}/payment/${pmt.paymentId}` );
    };

    return (
        <Container fluid>
            <CheckFormModal
                show={showAddCheck}
                setShow={setShowAddCheck}
                facilityId={facilityId}
                parkerId={parkerId}
                onSave={ (data) => {
                    Api.paymentCheckCreate( facilityId, parkerId, data ).then(
                        res => processResult( res,
                            val => setPayments( [...payments, parsePayment( val ) ] ) ),
                            err => console.log( err )
                        );
                } } />
            <Row>
                <Col>
                    <div className="d-flex gap-2 align-items-center">
                        <PageTitle>
                            Payments
                        </PageTitle>
                        <Button variant="sm" className="d-flex-align-center" onClick={ () => setShowAddCheck( true )}>
                            <CurrencyDollar color="red" /> Record Check
                        </Button>
                    </div>
                    {/* <Tabs
                        defaultActiveKey="payments"
                        id="parker-details-tabs"
                        className="mb-3 mt-4"
                        variant="underline" justify>
                        <Tab eventKey="payments" title="Payments"> */}
                            <div style={{height: "100%", width: '100%'}}>
                                <DataGrid
                                    onRowClicked={onRowClicked}
                                    rowData={payments}
                                    columnDefs={paymentColumns}
                                    onGridReady={onGridReady}
                                    domLayout='autoHeight'
                                />
                            </div>
                        {/* </Tab>
                    </Tabs> */}
                </Col>
            </Row>
        </Container>
    );
}