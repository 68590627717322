import { Duration, LocalDateTime, LocalTime } from "js-joda";
import { Money } from "./Money";
import { Params, useParams } from "react-router-dom";
import { ApiUrl } from "./ApiUrl";
import { ReactNode } from "react";

export function parseBoolean( v: string | boolean ): boolean {
    return v === "true" || v === true;
}

export function paramIds( pars: Params<string> ) {
    const result = Object.fromEntries( Object.entries( pars ).map( ( [key, value] ) => {
        return [key, parseInt( value! )]; // Or any transformation you want
    } ) );
    return result;
}

export function useParamIds() {
    const pars = useParams();
    const result = Object.fromEntries( Object.entries( pars ).map( ( [key, value] ) => {
        return [key, parseInt( value! )]; // Or any transformation you want
    } ) );
    return result;
}

export function useOptionalParamIds() {
    const pars = useParams();
    const result = Object.fromEntries( Object.entries( pars ).map( ( [key, value] ) => {
        return [key, value ? parseInt( value ) : undefined]; // Or any transformation you want
    } ) );
    return result;
}

type IsActive = ( d: Duration, a: LocalDateTime, b: LocalDateTime ) => boolean;
type Charge   = ( d: Duration, a: LocalDateTime, b: LocalDateTime ) => Money;

export enum ResetType {
    MaxDuration,    //restart the rate program after a certain duration
    MaxCharge,      //restart the rate program once a certain fee has been reached
    MaxTimeOfDay    //restart the rate program at a certain minute of the day
}

export interface DurationReset  { type: ResetType.MaxDuration;  duration:  Duration;  }
export interface ChargeReset    { type: ResetType.MaxCharge;    amount:    Money;     }
export interface TimeOfDayReset { type: ResetType.MaxTimeOfDay; timeOfDay: LocalTime; }
export type Reset = DurationReset | ChargeReset | TimeOfDayReset;

export interface ParkingRateFixed { unit:     Duration,                 }
export interface ParkingRateFx    { isActive: IsActive; charge: Charge; }
export interface ParkingRateUpTo  { upTo:     Duration; rate:   Money;  }
export interface RateProgram {
    name:   string;
    start?: LocalTime;
    end?:   LocalTime;
    rates:  ParkingRateUpTo[];
}

export interface MonthlyOption {
    name:  string;
    times: string;
    price: Money;
}

export function facilityImg( s: string ) {
    return `/${s}`;
}

export const facilityImgNew = ( imgId: number ) => ApiUrl.imageGet( imgId );

export function Cond( { show, children }: { show: boolean, children: ReactNode } ) {
    return show && children;
}