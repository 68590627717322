import { Alert, Col, Container, Row } from "react-bootstrap";

//spotsync controls

//spotsync data
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { HeaderText } from "./Controls/HeaderText";
import { ConnectNotificationBanner, ConnectBalances, ConnectAccountManagement, ConnectAccountOnboarding } from "@stripe/react-connect-js";
import { ExtendedFacility } from ".";
import { FacilityListSearch } from "./Controls/FacilityListSearch";
import { useEffect, useState } from "react";
import { SearchResult } from "./Data/ApiTypes";
import { useParamIds } from "./Data/Common";
import { parseSearchResult } from "./Data/ApiParse";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { EntityType } from "./Data/ApiTransport";
import { PageTitle } from "./Controls/PageTitle";

export function FacilityOverviewPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const facility = useRouteLoaderData( "facility" ) as ExtendedFacility;
    const acct     = facility.account;
    const [search, setSearch] = useState<string>();
    const [results, setResults] = useState<SearchResult[]>();

    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Overview
                </PageTitle>
            </Col>
        </Row>
        <Row>
            <Col xs="12" md="12">
                <ConnectNotificationBanner collectionOptions={{ fields: "eventually_due", futureRequirements: "include" }} />
                <br />
            </Col>
        </Row>
        <Row>
            <Col xs="12" md="12">
                <HeaderText>Balance</HeaderText>
                <ConnectBalances />
                <br />
            </Col>
        </Row>
        <Row>
            <Col>
                <ConnectAccountOnboarding
                    onExit={() => {
                        console.log( "The account has exited onboarding" );
                    }}>
                </ConnectAccountOnboarding>
            </Col>
        </Row>
        <Row>
            <Col>{acct.isOnboarded && <ConnectAccountManagement />}</Col>
        </Row>
        {!acct.isOnboarded && <Row>
            <Col>
                <Alert variant="warning">
                    You have not completed onboarding. <a target="_blank" href={acct.connectLink!}>
                        Click here to finish onboarding.
                    </a>
                </Alert>
            </Col>
        </Row>}
    </Container>;
}
