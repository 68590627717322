import { OrderType, PaymentType, OrderInitiator, OrderBalanceType, SubscriptionStatus } from "./ApiTransport";

export const showOrderType = ( type: OrderType ): string => {
    switch( type ) {
        case OrderType.Imported          : return 'Imported Account Balance';
        case OrderType.Reserve           : return 'Reservation';
        case OrderType.Subscribe         : return 'Plan Started';
        case OrderType.ModifySubscription: return 'Plan Modified';
        case OrderType.AccessPeriod      : return 'Plan Renewal';
        case OrderType.Custom            : return 'Custom';
    }
};

export const showPaymentType = ( type: PaymentType ): string => {
    switch( type ) {
        case PaymentType.ImportedPayment       : return 'Imported Account Credit';
        case PaymentType.CashPayment           : return 'Cash Payment';
        case PaymentType.CheckPayment          : return 'Check Payment';
        case PaymentType.CheckBounce           : return 'Bounce Check';
        case PaymentType.WriteCheck            : return 'Write Check';
        case PaymentType.ProcessorPayment      : return 'Online Payment';
        case PaymentType.ProcessorRefund       : return 'Online Refund';
        case PaymentType.ProcessorDisputeChange: return 'Dispute';
        case PaymentType.Adjustment            : return 'Adjustment';
        default:
            return 'Unknown Payment Type';
    }
};

export const showPaymentTypeAction = ( type: PaymentType ): string => {
    switch( type ) {
        case PaymentType.ImportedPayment       : return 'Account Credited';
        case PaymentType.CashPayment           : return 'Paid with Cash';
        case PaymentType.CheckPayment          : return 'Paid with Check';
        case PaymentType.CheckBounce           : return 'Check Bounced';
        case PaymentType.WriteCheck            : return 'Issued Check to Customer';
        case PaymentType.ProcessorPayment      : return 'Paid Online';
        case PaymentType.ProcessorRefund       : return 'Refunded Online';
        case PaymentType.ProcessorDisputeChange: return 'Withdrew Disputed Funds';
        case PaymentType.Adjustment            : return 'Account Adjusted';
        default:
            return 'Unknown Payment Type';
    }
};

export const showOrderInitiator = ( initiator: OrderInitiator ): string => {
    switch( initiator ) {
        case OrderInitiator.System: return 'System';
        case OrderInitiator.Parker: return 'Parker';
        case OrderInitiator.Staff:  return 'Staff';
        default:
            return 'Unknown Initiator';
    }
};

export const showOrderBalanceType = ( type: OrderBalanceType ): string => {
    switch( type ) {
        case OrderBalanceType.OrderPlaced     : return 'Order Placed';
        case OrderBalanceType.OrderFee        : return 'Order Fee';
        case OrderBalanceType.ApplyPayment    : return 'Payment Applied';
        case OrderBalanceType.UndoApplyPayment: return 'Payment Removed';
        case OrderBalanceType.RefundPayment   : return 'Payment Refund';
        case OrderBalanceType.OrderAdjustment : return 'Order Adjustment';
        default:
            return 'Unknown Balance Type';
    }
};

export const showSubscriptionStatus = ( status: SubscriptionStatus ): string => {
    switch ( status ) {
        case SubscriptionStatus.Applied: return 'Applied';
        case SubscriptionStatus.Rejected: return 'Rejected';
        case SubscriptionStatus.WaitingForContract: return 'Waiting for Contract';
        case SubscriptionStatus.Active:    return 'Active';
        case SubscriptionStatus.Cancelled: return 'Cancelled';
        default:
            return 'Unknown Status';
    }
};
