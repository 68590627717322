import { Col, Container, Row } from "react-bootstrap";
import { ArrowUpCircle, BarChartSteps, Calendar, Calendar2, Calendar2Check, ChatHeart, ChatRightDots, CurrencyDollar, EnvelopePaper, FilesAlt, House, HouseFill, IconProps, JournalAlbum, JournalBookmark, JournalCheck, ListCheck, People, PeopleFill, PiggyBank, Printer, Receipt, Ticket, TicketDetailed, TicketPerforated, TicketPerforatedFill } from "react-bootstrap-icons";
import { NavLink, Outlet, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { SearchResult } from "./Data/ApiTypes";
import { useEffect, useState } from "react";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { parseSearchResult } from "./Data/ApiParse";
import { EntityType } from "./Data/ApiTransport";
import { ExtendedFacility } from ".";
import { ConnectComponentsProvider } from "@stripe/react-connect-js";
import React from "react";

interface MyIconProps {
    size?:        number;
    strokeWidth?: number;
}

interface MenuItemProps {
    icon:     React.ReactElement<MyIconProps>;
    to:       string;
    text:     string;
    default?: boolean;
    active?:  boolean;
}

export function MenuItem( props: MenuItemProps ) {
    const { active = false } = { ...props };
    const iconWithProps = React.cloneElement<IconProps>( props.icon, {
        size: props.icon.props.size !== undefined ? 24 : props.icon.props.size,
    } );
    return <NavLink to={props.to}
                    className={`facility-menu-item ${active ? "my-active" : "" }`}
                    end={props.default}>
        <div className="grid-col-2 align-self-center d-flex-align-center">
            {iconWithProps}
        </div>
        <div className="grid-col-4 align-self-center d-flex-align-center">
            {props.text}
        </div>
    </NavLink>;
}

export function AppFacility() {
    const loc        = useLocation();
    const facility   = useLoaderData() as ExtendedFacility;
    const facilityId = facility.facilityId;

    //for handling mobile and desktop versions of these pages
    const [menuClassName, contentClassName] = loc.pathname === ( `/facility/${facilityId}` ) ? ["", "d-none"] : ["d-none", ""];
    const activeOverride = loc.pathname === ( `/facility/${facilityId}` ) ? true : false;
    return <Container fluid className="fullheight pt-2">
        <ConnectComponentsProvider connectInstance={facility.connectInstance}>
            <Row className="fullheight">
                <Col md={5} lg={5} xl={4} xxl={3} id="facility-menu" className={`${menuClassName} d-md-grid fullheight scroll-y-auto border-end`}>
                    <MenuItem active={activeOverride} default icon={<House />} to="./overview" text="Overview" />
                    <MenuItem icon={<CurrencyDollar />} to="./payments"     text="Payments" />
                    <MenuItem icon={<PiggyBank style={{ transform: "scaleX(-1)"}}     />} to="./payouts"      text="Payouts"  />
                    <MenuItem icon={<ListCheck      />} to="./listing"      text="Listing"  />
                    <MenuItem icon={<People         />} to="./parker"       text="Parkers"  />
                    <MenuItem icon={<Calendar       />} to="./plan"         text="Plans"    />
                    <MenuItem icon={<Ticket         />} to="./rate"         text="Rates"    />
                    <MenuItem icon={<BarChartSteps  />} to="./report/aging" text="Aging"    />
                    <MenuItem icon={<EnvelopePaper  />} to="./invoice"      text="Invoices" />
                </Col>
                <Col md={7} lg={7} xl={8} xxl={9}
                     className={`${contentClassName} p-0 m-0 d-md-grid h-100 scroll-y-auto`}
                     style={{ gridTemplateRows: "1fr" }}>
                    <div className="d-md-none w-100 p-3 d-flex justify-content-center d-print-none">
                        <NavLink to="" className="d-flex align-items-center gap-1">
                            <ArrowUpCircle />Return to Facility Menu
                        </NavLink>
                    </div>
                    <Outlet />
                </Col>
            </Row>
        </ConnectComponentsProvider>
    </Container>;
}