import { ReactNode, useState } from "react";
import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";

//spotsync controls
import { Hyperlink } from "./Controls/Hyperlink";
import { PageTitle } from "./Controls/PageTitle";
import { ContactModal } from "./FacilityParkerDetailsPageContactModal";
import { cents, Money, showMoney, toCents } from "./Data/Money";
import { add } from "dinero.js";
import { useLoaderData } from "react-router-dom";
import { ParkerDetails, ParkerUpdateReq } from "./Data/ApiTypes";
import { showDateTime } from "./Data/Date";
import { Cond, useParamIds } from "./Data/Common";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { Button } from "./Controls/Button";
import { Pencil } from "react-bootstrap-icons";
import { ClipboardLink } from "./Controls/ClipboardLink";
import React from "react";

function balanceColor( val: number ) {
    if( val <= 0 )
        return "success";
    return "warning";
}

interface BigStatProps {
    label: ReactNode;
    value: Money;
    good?: boolean;
    warn?: boolean;
    bad?:  boolean;
}

const BigStat = ( { label, value }: BigStatProps ) => {
    return <Cond show={true}>
        <div style={{ flex: "1 1 0" }}>
            <div className={`text-secondary text-center`}>
                {label}
            </div>
            <div className={`fs-4 text-center`}>
                {showMoney( value )}
            </div>
        </div>
    </Cond>;
}

export function FacilityParkerDetailsPage() {
    const { facilityId, parkerId } = useParamIds();
    const [showContactInfo, setShowContactInfo] = useState( false );
    const loadedParker = useLoaderData() as ParkerDetails;
    const [parker, setParker] = useState( loadedParker );

    const reservations  = parker.reservations;
    const subs          = parker.subscriptions;
    const resTotal      = reservations.map( r => r.price ).reduce( (a, b) => add( a, b ), cents( 0 ) );
    const monthlySpend  = subs.map( s => s.price ? cents( s.price ) : cents( 0 ) )
                              .reduce( (a, b) => add( a, b ), cents( 0 ) );

    function saveContact( data: ParkerUpdateReq ): void {
        Api.parkerUpdate( parkerId, data ).then(
            resp => processResult( resp,
                val => setParker( { ...parker, ...data } ),
                err => undefined
        ) );
    }

    return (
        <Container fluid>
            <ContactModal parker={parker}
                          show={showContactInfo}
                          setShow={setShowContactInfo}
                          onSave={ saveContact } />
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-2 mb-0">
                        <PageTitle>
                            {parker.name}
                        </PageTitle>
                        <div>
                            <Button variant="sm" className="d-flex-align-center" onClick={ () => setShowContactInfo( true )}>
                                <Pencil /> Edit Parker
                            </Button>
                        </div>
                    </div>
                    <div className="gap-2" 
                         style={{                             
                             display:             "inline-grid", 
                             alignItems:          "center",                             
                             justifyItems:        "start",
                             gridTemplateColumns: "min-content auto",
                             gridTemplateRows:    "auto auto"
                        }}>
                        {!!parker.phoneNumber  && <React.Fragment>
                            <span>Phone</span> <ClipboardLink>{parker.phoneNumber}</ClipboardLink>
                        </React.Fragment>}
                        {!!parker.emailAddress && <React.Fragment>
                            <span>Email</span> <ClipboardLink> {parker.emailAddress}</ClipboardLink>
                        </React.Fragment>}
                    </div>
                    <div className="w-100 column-gap-5 px-4 my-4 d-flex">
                        <BigStat good={toCents( parker.balance ) <= 0 } label="Balance" value={parker.balance   }></BigStat>
                        <BigStat label="Monthly"          value={monthlySpend     }></BigStat>
                        <BigStat label="Reservations"     value={resTotal         }></BigStat>
                    </div>
                </Col>
                <Col md="12" className="g-0 px-0">
                <Card className="mb-4">
                        <Card.Body>
                            <Row className="mb-4">
                                <Col sm={12} className="h4">
                                    Active Plans
                                </Col>
                                <Col sm={12}>
                                    {subs.length === 0 ? (
                                        <p>This parker does not have any active parking plans</p>
                                    ) : (
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th className="text-center">Quantity</th>
                                                    <th className="text-center">Unit Price</th>
                                                    <th className="text-center">Monthly Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subs.map(sub => {
                                                    const s = sub;
                                                    return (
                                                        <tr key={s.subscriptionId}>
                                                            <td>
                                                                <Hyperlink to={`/facility/${facilityId}/parker/${parkerId}/plan/${s.subscriptionId}`}>
                                                                    {s.plan.name}
                                                                </Hyperlink>
                                                            </td>
                                                            <td className="text-center">{s.quantity}</td>
                                                            <td className="text-center">{s.unitPrice && showMoney( cents( s.unitPrice ))}</td>
                                                            <td className="text-center">{s.price && showMoney( cents( s.price ) ) }</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className="h4">
                                    Reservations
                                </Col>
                                <Col sm={12}>
                                    {reservations.length === 0 ? (
                                        <p>This parker has not made any reservations</p>
                                    ) : (
                                        <Table hover striped>
                                            <thead>
                                                <tr>
                                                    <th>Rate</th>
                                                    <th>Check-In</th>
                                                    <th>Check-Out</th>
                                                    <th>Vehicle</th>
                                                    <th className="text-end">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reservations.map(r => (
                                                    <tr key={r.reservationId}>
                                                        <td>
                                                            <Hyperlink to="#">
                                                                {r.rateProgram.name}
                                                            </Hyperlink>
                                                        </td>
                                                        <td>{showDateTime(r.start)}</td>
                                                        <td>{showDateTime(r.end)}</td>
                                                        <td>Not Specified</td>
                                                        <td className="text-end">{showMoney(r.price)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col sm={12} className="h4">
                                    Vehicles
                                </Col>
                                <Col sm={12}>
                                    {parker.vehicles.length === 0 ? (
                                        <p>This parker has not added any vehicles</p>
                                    ) : (
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th>Make/Model</th>
                                                    <th>Color</th>
                                                    <th>License Plate</th>
                                                    <th>State</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {parker.vehicles.map(vehicle => (
                                                    <tr key={vehicle.vehicleId}>
                                                        <td>{vehicle.make} {vehicle.model}</td>
                                                        <td>{vehicle.color}</td>
                                                        <td>{vehicle.licensePlateNumber}</td>
                                                        <td>{vehicle.stateCode}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}