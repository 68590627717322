import { Badge } from "react-bootstrap";
import { Money, cents, showMoney, showMoneyShort } from "../Data/Money";
import { equal } from "dinero.js";

export function OrderPaidBadge( { balance }: { balance: Money } ) {
    const zero = cents( 0 );
    const isPaid = equal( balance, zero );
    return <Badge className="border border-primary bg-white fw-normal text-primary">
        {isPaid ? "Paid" : `Parker owes ${showMoneyShort( balance )}`}
    </Badge>;
}
