import { AllCommunityModule, GridReadyEvent, ICellRendererParams, ModuleRegistry } from "ag-grid-community";
import { ColDef, ColDefField, themeQuartz } from "ag-grid-community";
import { cents, Money, showMoney } from "../Data/Money";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { useCallback, useMemo, useRef } from "react";
import { showInstantDate, showInstantDateTime } from "../Data/Date";
import { compare, equal } from "dinero.js";
import { CheckCircleFill, Download, Search, XCircleFill } from "react-bootstrap-icons";
import { Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "./Button";
import { LocalDateTime, LocalDate, Instant, Duration } from "js-joda";

ModuleRegistry.registerModules( [AllCommunityModule] );

export function colText<T>( field: ColDefField<T> ): ColDef<T>
    { return { field: field }; }

export function colNumber<T>( field: ColDefField<T> ): ColDef<T>
    { return { field: field, type: "rightAligned" }; }

export function colLength<T>( field: ColDefField<T> ): ColDef<T>
    { return { field: field, valueFormatter: v => (v.value as Array<any>).length.toString(), type: "rightAligned" }; }

export function colMoney<T>( field: ColDefField<T> ): ColDef<T>
    { return { field: field, valueFormatter: x => showMoney( x.value ), type: "rightAligned", comparator: compare }; }

export function colInstantDate<T>( field: ColDefField<T>, func: ( arg: T ) => string ): ColDef<T>
    { return { field: field, valueFormatter: x => showInstantDate( x.value, func( x.data! ) ), type: "rightAligned" }; }

export function colInstantDateTime<T>( field: ColDefField<T>, func: ( arg: T ) => string ): ColDef<T>
    { return { field: field, valueFormatter: x => showInstantDateTime( x.value, func( x.data! ) ), type: "rightAligned" }; }

export function BalanceRenderer( params: ICellRendererParams<any, Money> ) {
    if( !params.value ) {
        return <></>;
    }
    const paid = equal( params.value!, cents( 0 ) );
    return <div className={`d-flex align-items-center justify-content-end gap-1 ${paid ? "text-black" : "text-danger"}`}>
        <div>
            {showMoney( params.value! )}
        </div>
    </div>;
}

export function BalanceStatusRenderer( params: ICellRendererParams<any, Money> ) {
    if( !params.value ) {
        return <></>;
    }
    const paid = equal( params.value!, cents( 0 ) );
    const size    = 16;
    const textCls = paid ? "text-black" : "text-danger";
    return <div className="d-flex align-items-center justify-content-start gap-1">
        {paid ? <CheckCircleFill color="green" size={size} className={textCls}  />
              : <XCircleFill size={size} className={textCls} /> }
        <div className={textCls}>
            {paid ? 'Paid' : 'Unpaid'}
        </div>
    </div>;
}

export const gridTheme = themeQuartz.withParams( {
    fontFamily:          "silka",
    headerFontFamily:    "silka",
    cellFontFamily:      "silka",
    wrapperBorderRadius: "0px 0px 8px 8px",
    wrapperBorder:       false
} );

export interface DataGridProps extends AgGridReactProps {}

export const xsCol  = ``;
export const smCol  = `d-none d-sm-block`;
export const mdCol  = `d-none d-md-block`;
export const lgCol  = `d-none d-lg-block`;
export const xlCol  = `d-none d-xl-block`;
export const xxlCol = `d-none d-xxl-block`;

export interface GridForm {
    searchText: string;
}

export function DataGrid( props: DataGridProps ) {
    const gridRef = useRef<AgGridReact>( null );
    const { register, watch } = useForm<GridForm>( { defaultValues: { searchText: "" } } );

    const defaultColDef = useMemo<ColDef>( () => ( {
        sortable: true,
        filter:   true,
    } ), [] );

    const onGridReady = ( params: GridReadyEvent ) => {
        params.api.sizeColumnsToFit();
    };

    const handleExport = useCallback( () => {
        gridRef.current!.api.exportDataAsCsv();
    }, [] );

    const searchText = watch( "searchText" );
    const exportTooltip = <Tooltip>Export as CSV</Tooltip>
    return <div className="h-100 border" style={{ display: "grid", gridTemplateRows: "auto 1fr" }}>
        <Card className="rounded-0 border-0">
            <Card.Header className="border-bottom ps-3 pe-3">
                <div className="d-flex align-items-center gap-3">
                    <div className="d-flex-align-center flex-grow-1 gap-3">
                        <Search /> <Form.Control {...register( "searchText" )} size="sm" className="mx-0" />
                    </div>
                    <Download style={{ cursor: "pointer" }} onClick={handleExport} />
                </div>
            </Card.Header>
        </Card>
        <AgGridReact
            ref={gridRef}
            quickFilterText={searchText}
            containerStyle={{ borderTop: 0 }}
            defaultColDef={defaultColDef}
            animateRows={false}
            theme={gridTheme}
            onGridReady={onGridReady}
            {...props} />
    </div>
}