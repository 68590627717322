import { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";

export interface MediumPageProps {
    children: ReactNode;
}

export function LargePage( props: MediumPageProps ) {
    return <Container fluid>
        <Row className="justify-content-center">
            <Col>
                {props.children}
            </Col>
        </Row>
    </Container>;
}