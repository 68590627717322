import { Card, Col, Container, Row } from "react-bootstrap";
import { useLoaderData } from "react-router-dom";
import { OrderDetails } from "./Data/ApiTypes";
import { PageTitle } from "./Controls/PageTitle";
import { cents, showMoney, toCents } from "./Data/Money";
import { showOrderBalanceType, showOrderType } from "./Data/Finance";
import { showInstantDateTime } from "./Data/Date";
import { equal } from "dinero.js";
import { Hyperlink } from "./Controls/Hyperlink";
import { MediumPage } from "./Controls/MediumPage";
import { OrderPaidBadge } from "./Controls/OrderPaidBadge";

export function FacilityParkerOrderDetailsPage() {
    const orderDetails = useLoaderData() as OrderDetails;
    const order        = orderDetails.order;
    const facility     = orderDetails.order.facility;
    const chgs         = orderDetails.balanceChanges;
    const last         = chgs.sort( (a, b) => a.orderBalanceId - b.orderBalanceId ).toReversed()[0];
    const bal          = last.balance;

    let status = "Open";
    if( equal( bal, cents( 0 ) ) ) {
        status = "Paid"
    }

    return <MediumPage>
        <Row>
            <Col>
                <PageTitle>
                    Order #{order.orderId}
                </PageTitle>
                <Container className="g-0">
                    <Row>
                        <Col className="fs-6">
                            <table className="table w-auto" cellPadding={4}>
                                <tr>
                                    <td>{showOrderType( order.type )}</td>
                                    <td>{order.summary}</td>
                                </tr>
                                <tr>
                                    <td>Order Status</td>
                                    <td className="text-end">
                                        <OrderPaidBadge balance={order.balance.balance} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Order Price</td>
                                    <td className="text-end">{showMoney( last.totalPaid )}</td>
                                </tr>
                                {equal( order.price, last.totalDebt ) !== true && <tr>
                                    <td>Price (Late Fees)</td>
                                    <td className="text-end">{showMoney( last.totalDebt )}</td>
                                </tr>}
                                <tr>
                                    <td>Total Paid</td>
                                    <td className="text-end">{showMoney( last.totalPaid )}</td>
                                </tr>
                                <tr className="fw-bold">
                                    <td>Amount Due</td>
                                    <td className="text-end">{showMoney( last.balance )}</td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                </Container>
                <PageTitle>
                    Timeline
                </PageTitle>
                {chgs.map( chg => {
                    return <Card key={chg.orderId} className="my-2">
                        <Card.Header className="text-start fw-bold" style={{ display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                            <div>{showOrderBalanceType( chg.type )} </div>
                            <div className="text-end">
                                {showInstantDateTime( chg.createdWhen, facility.timeZone ) }
                            </div>
                        </Card.Header>
                        <Card.Body className="purchase-layout gap-2">
                            <div className="grid-area-a">
                                {!equal( cents( 0 ), chg.paid ) && <>
                                    <Hyperlink to={`/facility/${facility.facilityId}/parker/${order.parkerId}/payment/${chg.paymentId}`}>
                                        Payment
                                    </Hyperlink> of {showMoney( chg.paid )} Applied
                                </>}
                                {!equal( cents( 0 ), chg.debt ) && <>Amount Due Increased by {showMoney( chg.debt )}</>}
                            </div>
                            <div className="grid-area-b fw-bold">

                            </div>
                            <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                                <div className="fw-bold">
                                    {showMoney( chg.balance )}
                                </div>
                                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                    {/* {toCents( chg.balance ) > 0 && <>Outstanding</>} */}
                                </div>
                            </div>
                            <div className="grid-area-d text-end d-flex gap-2">
                            </div>
                        </Card.Body>
                    </Card>;
                } )}
            </Col>
        </Row>
    </MediumPage>;
}