import { useState } from "react";
import { AddVehicleModal } from "./Controls/AddVehicleModal";
import { Button } from "./Controls/Button";
import { VehicleCard } from "./Controls/VehicleCard";
import { push } from "./Data/Util";
import { PageTitle } from "./Controls/PageTitle";
import { Vehicle, VehicleDetails } from "./Data/ApiTypes";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { SmallPage } from "./Controls/SmallPage";
import { MediumPage } from "./Controls/MediumPage";
import { useParamIds } from "./Data/Common";

export function FacilityParkerVehicleListPage() {
    const { facilityId, parkerId } = useParamIds();
    const loadedVehicles = useLoaderData() as VehicleDetails[];
    const parker         = useRouteLoaderData("parker") as VehicleDetails[];
    const [showAddVehicle, setShowAddVehicle] = useState( false );
    const [vehicles, setVehicles] = useState( [...loadedVehicles] );

    function addVehicle( veh: Vehicle ) {
        setVehicles( push( loadedVehicles, { ...veh, links: [] } ) );
    }

    return <MediumPage>
        <AddVehicleModal show={showAddVehicle} setShow={setShowAddVehicle} onSubmit={addVehicle} />
        <PageTitle>{} Vehicles</PageTitle>
        {loadedVehicles.length === 0 && <>This parker does not have any vehicles</>}
        {loadedVehicles.map( ( veh, index ) =>
            <VehicleCard veh={veh} setVehicles={setVehicles} vehicles={loadedVehicles} />
        )}
        {/* <div className="text-end">
            <Button className="w-100" onClick={() => setShowAddVehicle( true )}>Add Vehicle</Button>
        </div> */}
    </MediumPage>;
}