
const detectServ = ( url: string ) => {
    const loc = url;
    if( loc.includes( "localhost" ) || loc.includes( "local.dev" ) ) {
        return "https://local.dev:7063/monitor";
    }
    //else we are on a dev server
    if( loc.includes( "monitor.dev.spotsync.com" ) ) {
        return 'https://api.dev.spotsync.com/monitor';
    }
    //we are live
    return 'https://api.spotsync.com/monitor';
}
const serv   = detectServ( window.location.href );
const url    = ( tail: string ) => { return `${serv}${tail}`; }
const facUrl = ( tail: string ) => url( `/facility${tail}` );
const encode = ( text: string ) => encodeURIComponent( text );

export class ApiUrl {

    //operator
    static opDetails() { return url( `/` ); }

    //staff
    static invite()                                   { return url( `/auth/invite`                       ); } //GET
    static amILoggedIn()                              { return url( `/auth/`                             ); } //GET
    static logIn()                                    { return url( `/auth/login`                        ); } //POST
    static logOut()                                   { return url( `/auth/logout`                       ); } //POST
    static forgotPassword()                           { return url( `/auth/forgot`                       ); } //POST
    static changeEmailAddress()                       { return url( `/auth/email`                        ); } //POST
    static changePassword()                           { return url( `/auth/password`                     ); } //POST
    static deleteAccount()                            { return url( `/auth/delete`                       ); } //GET (retrieves a random secret)
    static deleteAccountConfirm()                     { return url( `/auth/delete-confirm`               ); } //POST

    //images
    static imageGet( imgId: number ) { return url( `/img/${imgId}` ); } //GET

    //parker vehicle functionality
    static vehicleCreate                = ()                                 => url( '/vehicle/add' );                     //POST
    static vehicleUpdate                = ( vehId: number )                  => url( `/vehicle/${vehId}` );                //POST
    static vehicleSearch                = ()                                 => url( '/vehicle' );                         //GET
    static vehicleDetails               = ( vehId: number )                  => url( `/vehicle/vehicle/${vehId}` );        //GET
    static vehicleDelete                = ( vehId: number )                  => url( `/vehicle/vehicle/${vehId}/delete` ); //POST

    //facilities
    static facilityCreate               = ()                               => facUrl( `/add`                                    ); //POST
    static facilityUpdate               = ( facId: number )                => facUrl( `/${facId}`                               ); //POST
    static facilityList                 = ()                               => facUrl( ``                                        ); //POST
    static facilitySearch               = ( facId: number, query: string ) => facUrl( `/${facId}/search?query=${encode(query)}` ); //GET
    static facilityDetails              = ( facId: number )                => facUrl( `/${facId}`                               ); //GET
    static facilityParkers              = ( facId: number )                => facUrl( `/${facId}/parker`                        ); //GET
    static facilityInvoiceList          = ( facId: number )                => facUrl( `/${facId}/invoice`                       ); //GET
    static facilityParkerDetails        = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}`               ); //GET
    static facilityParkerVehicleList    = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/vehicle`       ); //GET

    static facilityParkerOrderList      = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/order`         ); //GET
    static facilityParkerOrderOpenList  = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/order/open`    ); //GET
    static facilityParkerInvoiceList    = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/invoice`    ); //GET
    static facilityParkerPaymentList    = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/payment`     ); //GET

    static facilityParkerOrderDetails   = ( facId: number, parId: number, ordId: number ) => facUrl( `/${facId}/parker/${parId}/order/${ordId}`   ); //GET
    static facilityParkerInvoiceDetails = ( facId: number, parId: number, invId: number ) => facUrl( `/${facId}/parker/${parId}/invoice/${invId}` ); //GET
    static facilityParkerPaymentDetails = ( facId: number, parId: number, pmtId: number ) => facUrl( `/${facId}/parker/${parId}/payment/${pmtId}` ); //GET

    static facilityParkerCheckCreate    = ( facId: number, parId: number ) => facUrl( `/${facId}/parker/${parId}/payment/check` ); //POST

    //reports
    static facilityReportAging     = ( facId: number ) => url( `/report/facility/${facId}/aging` ); //GET
    static facilityReportTableList = ( facId: number ) => url( `/report/facility/${facId}/table` ); //GET
    static facilityReportQuery     = ( facId: number ) => url( `/report/facility/${facId}/query` ); //POST

    //facility plan
    static facilityPlanList        = ( facId: number )                 => facUrl( `/${facId}/plan` );                 //GET
    static facilityPlanCreate      = ( facId: number )                 => facUrl( `/${facId}/plan` );                 //POST
    static facilityPlanGet         = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}` );       //GET
    static facilityPlanUpdate      = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}` );       //POST
    static facilityPlanPriceCreate = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}/price` ); //POST
    static facilityPlanPriceList   = ( facId: number, planId: number ) => facUrl( `/${facId}/plan/${planId}/price` ); //GET

    //facility subscription
    static facilitySubscriptionList   = ( facId: number )                => facUrl( `/${facId}/subscription`          ); //GET
    static facilitySubscriptionGet    = ( facId: number, subId: number ) => facUrl( `/${facId}/subscription/${subId}` ); //GET
    static facilitySubscriptionUpdate = ( facId: number, subId: number ) => facUrl( `/${facId}/subscription/${subId}` ); //POST

    //facility rate
    static facilityRateList     = ( facId: number )                 => facUrl( `/${facId}/rate` );           //GET
    static facilityRateCreate   = ( facId: number )                 => facUrl( `/${facId}/rate/create` );    //POST
    static facilityRateGet      = ( facId: number, progId: number ) => facUrl( `/${facId}/rate/${progId}` ); //GET
    static facilityRateUpdate   = ( facId: number, progId: number ) => facUrl( `/${facId}/rate/${progId}` ); //POST
    static facilityParkerInvite = ( facId: number )                 => facUrl( `/${facId}/invite` );    //POST

    //parker
    static parkerUpdate( id: number ) { return url( `/parker/${id}` ); } //POST

    //e-commerce
    static stripeInit( facId: number ) { return url( `/stripe/${facId}/init` ); } //POST
    static stripeLink( facId: number ) { return url( `/stripe/${facId}/link` ); } //POST
}