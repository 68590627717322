import { useState } from "react";
import { VehicleCard } from "./Controls/VehicleCard";
import { PageTitle } from "./Controls/PageTitle";
import { VehicleDetails } from "./Data/ApiTypes";
import { useLoaderData } from "react-router-dom";
import { useParamIds } from "./Data/Common";
import { MediumPage } from "./Controls/MediumPage";

export function FacilityParkerVehicleDetailsPage() {
    const { vehicleId } = useParamIds();
    const loadedVehicles = useLoaderData() as VehicleDetails[];
    const [showAddVehicle, setShowAddVehicle] = useState( false );
    const [vehicles, setVehicles] = useState( [...loadedVehicles] );
    const vehicle = loadedVehicles.find( v => v.vehicleId === vehicleId );

    return <MediumPage>
        <PageTitle>Vehicle Details</PageTitle>
        <VehicleCard veh={vehicle!} setVehicles={setVehicles} vehicles={loadedVehicles} />
    </MediumPage>;
}