import { Card, Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { Invoice, ParkerDetails } from "./Data/ApiTypes";
import { showMoney } from "./Data/Money";
import { showDate, showInstantDate } from "./Data/Date";
import { showAddressHtml } from "./Data/Location";
import { MediumPage } from "./Controls/MediumPage";
import { Envelope, Telephone } from "react-bootstrap-icons";
import { showOrderType } from "./Data/Finance";
import { LargePage } from "./Controls/LargePage";

export function FacilityParkerInvoiceDetailsPage() {
    const invoice  = useLoaderData()              as Invoice;
    const parker   = useRouteLoaderData("parker") as ParkerDetails;
    const facility = invoice.facility;
    const order    = invoice.order;
    return <LargePage>
            <div className="p-0 border-0 p-md-3 shadow-md mt-3 border-0 rounded-0">
                <Row>
                    <Col xs={6}>
                    <div className="d-flex flex-column">
                            <strong className="fs-5 text-uppercase">
                                {facility.name}
                            </strong>
                            <div>
                                {showAddressHtml( facility.address )}
                            </div>
                            <div className="mt-1 d-flex-align-center gap-2">
                                <Envelope color="maroon" /> {facility.emailAddress}
                            </div>
                            <div className="d-flex-align-center gap-2">
                                <Telephone color="maroon" /> {facility.phoneNumber}
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} className="text-md-end">
                        <div>
                            <span className="fw-semibold text-secondary">INVOICE</span>
                            &nbsp;
                            <span className="fw-bold">
                                #{invoice.invoiceNumber}
                            </span>
                        </div>
                        <div>
                            {showInstantDate( invoice.createdWhen, facility.timeZone )}
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row className="mb-4 gy-2">
                    <Col lg={4}>
                        <div className="fs-4 text-secondary text-uppercase">
                            Parker
                        </div>
                        <div className="d-flex flex-column">
                            <div>
                                {parker.name}
                            </div>
                            <div className="d-flex-align-center gap-2">
                                <Envelope color="maroon" />{parker.emailAddress}
                            </div>
                            {!!parker.phoneNumber && <div className="d-flex-align-center gap-2">
                                <Telephone color="maroon" /> {parker.phoneNumber}
                            </div>}
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="fs-4 ms-1 text-secondary text-uppercase">
                            Details
                        </div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>Service Type</td>
                                    <td>{showOrderType( order.orderType )}</td>
                                </tr>
                                <tr>
                                    <td>Service Details</td>
                                    <td>{order.summary}</td>
                                </tr>
                                <tr>
                                    <td>Amount Due</td>
                                    <td className="">{showMoney( invoice.endingBalance )}</td>
                                </tr>
                                <tr>
                                    <td>Due Date</td>
                                    <td>{showDate( invoice.dueWhen )}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-muted d-flex flex-column">
                            <div>Note: Make checks payable to {facility.name}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </LargePage>
}