import React from "react";
import { ReactNode, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Copy, IconProps } from "react-bootstrap-icons";

interface ClipboardLinkProps {
    children:         ReactNode;
    icon?:            React.ReactElement<IconProps>;
    copyText?:        string;
    tooltipDuration?: number;
}

export function ClipboardLink( {
    children,
    icon = <Copy color="" className="small" />,
    copyText,
    tooltipDuration = 1000
}: ClipboardLinkProps ) {
    const [showTooltip, setShowTooltip] = useState( false );

    async function handleCopy() {
        try {
            const textToCopy = copyText ?? ( typeof children === 'string' ? children : '' );
            await navigator.clipboard.writeText( textToCopy );
            setShowTooltip( true );
            setTimeout( () => setShowTooltip( false ), tooltipDuration );
        } catch( err ) {
            console.error( 'Failed to copy text:', err );
        }
    }

    const iconWithProps = React.cloneElement( icon, { onClick: handleCopy, style: { cursor: 'pointer' } } );
    return <OverlayTrigger
        placement="top"
        show={showTooltip}
        //TODO: this position-fixed workaround is weird!
        //https://stackoverflow.com/questions/74726036/how-to-fix-bootstrap-react-tooltip-blinking-scrolled-in-body
        overlay={<Tooltip className="position-fixed">Copied!</Tooltip>}>
        <span className="d-inline-flex gap-1 align-items-center">
            <span className="user-select-all">{children}</span>
            {iconWithProps}
        </span>
    </OverlayTrigger>;
}