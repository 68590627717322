import { emitInviteStaffReq, emitSubscriptionUpdateReq } from "./ApiEmit";
import { parseFacilityInvoice, parseInvoice, parseOrderDetails, parseParkerInfo, parsePayment, parsePlan, parsePlanDetails, parseStaff, parseSubEntry, parseSubEntryForEdit, parseVehicleDetails } from "./ApiParse";
import {
    CheckCreateReqApi,
    FacilityApi,
    FacilityCreateReqApi,
    FacilitySubApi,
    FacilityUpdateReqApi,
    InviteParkerReqApi,
    InvoiceApi,
    OperatorApi,
    OrderApi,
    OrderDebtApi,
    OrderDetailsApi,
    ParkerAgingRecordApi,
    ParkerDetailsApi,
    ParkerListEntryApi,
    PaymentApi,
    PlanApi,
    PlanCreateReqApi,
    PlanPriceAddReqApi,
    PlanPriceListRespApi,
    PlanUpdateReqApi,
    RateProgramApi,
    RateProgramDataApi,
    SearchResultApi,
    StripeLinkRespApi,
} from "./ApiTransport";
import { InviteStaffReq, ParkerUpdateReq, SubscriptionUpdateReq, Vehicle } from "./ApiTypes";
import { ApiUrl } from "./ApiUrl";
import { OkResult, Result } from "./Result";

export const notSet = ( a: any ) => a === null;


export interface LogInReq {
    emailAddress: string;
    password:     string;
}

export interface SignUpReq {
    emailAddress:    string;
    name:            string;
    password:        string;
    passwordConfirm: string;
}

export interface ChgPassReq {
    currentPassword: string;
    newPassword:     string;
}

export interface ChgEmailAddressReq {
    currentPassword: string;
    newEmailAddress: string;
}

export interface SignUpResp {
    staffId:      number;
    operatorId:   number;
    role:         number;
    name:         string;
    emailAddress: string;
}

export interface ConnectInitResp {
    clientSecret: string;
}

export type LogInResp    = SignUpResp;
export type ChgPassResp  = SignUpResp;
export type ChgEmailResp = SignUpResp;

export interface ForgotPassReq  { emailAddress: string; }
export type ForgotPassResp = string;

export const showRole = ( r: number ) => {
    if( r === 0 ) { return "Site Admin"; }
    if( r === 1 ) { return "Operator Admin"; }
    return "Err!";
}

export const parseArray = <TValApi, TVal>( func: ( api: TValApi ) => TVal ) => ( x: TValApi[] ) => x.map( func );

const getArgs = (): RequestInit => {
    return {
        method:      "GET",
        credentials: "include"
    }
}

const jsonArgs = ( verb: string, payload: any ): RequestInit => {
    return {
        method:      verb,
        headers:   { "Content-Type": "application/json" },
        body:        JSON.stringify( payload ),
        credentials: "include"
    }
}

const postArgs = ( payload: any ) => jsonArgs( "POST",   payload );
const resOk    = <T>( value: T ): OkResult<T>          => ({ isOk: true, value: value })
const ok       = <T>( value: T ): Promise<OkResult<T>> => Promise.resolve( resOk( value ) );
const okDelay = <TErr, TVal>( value: TVal ): Promise<Result<TErr, TVal>> =>
    new Promise( ( resolve ) =>
        setTimeout( () => { resolve( resOk( value ) ); }, 500 )
);

export const api = <TVal>( url: string, init: RequestInit = {} ): Promise<Result<string, TVal>> => {
    return fetch( url, init )
        .then( response => {
            const json   = response.json();
            const isOk = (response.status == 200);
            return json.then( data => {
                if( isOk ) {
                    return { isOk: true, value: data };
                }
                return { isOk: false, error: data }
            } );
        } );
}

const api2 = <TValApi, TVal>( url: string, init: RequestInit = {}, f: (x: TValApi) => TVal ): Promise<Result<string, TVal>> => {
    return fetch( url, init )
        .then( response => {
            const json = response.json();
            const isOk = (response.status == 200);
            return json.then( data => {
                if( isOk ) {
                    const parsed = f( data );
                    return { isOk: true, value: parsed };
                }
                return { isOk: false, error: data }
            } );
        } );
}

export class Api {

    //auth stuff
    static invite             = ( req: InviteStaffReq       ) => api2( ApiUrl.invite(), postArgs( emitInviteStaffReq( req ) ), parseStaff );
    static logIn              = ( req: LogInReq             ) => api<LogInResp>     ( ApiUrl.logIn(),               postArgs( req ) );
    static logOut             = (                           ) => api<LogInResp>     ( ApiUrl.logOut(),              postArgs( {}  ) );
    static changePassword     = ( req: ChgPassReq           ) => api<ChgPassResp>   ( ApiUrl.changePassword(),      postArgs( req ) );
    static changeEmailAddress = ( req: ChgEmailAddressReq   ) => api<ChgEmailResp>  ( ApiUrl.changeEmailAddress(),  postArgs( req ) );
    static forgotPassword     = ( req: ForgotPassReq        ) => api<ForgotPassResp>( ApiUrl.forgotPassword(),      postArgs( req ) );
    static amILoggedIn        = (                           ) => api<LogInResp>     ( ApiUrl.amILoggedIn(),         getArgs()       );

    //facilities
    static facilityCreate     = ( req: FacilityCreateReqApi ) => api<string>        ( ApiUrl.facilityCreate(                ), postArgs( req ) );
    static facilityUpdate     = ( req: FacilityUpdateReqApi ) => api<string>        ( ApiUrl.facilityUpdate( req.facilityId ), postArgs( req ) );
    static facilityList       = (                           ) => api<FacilityApi[]> ( ApiUrl.facilityList(                  ), getArgs() );
    static facilityDetails    = ( facilityId: number        ) => api<FacilityApi>   ( ApiUrl.facilityDetails( facilityId    ), getArgs() );

    static facilitySearch = ( facilityId: number, query: string ) =>
        api<SearchResultApi[]>( ApiUrl.facilitySearch( facilityId, query ), getArgs() );

    static facilityParkers( facilityId: number )
        { return api<ParkerDetailsApi[]>( ApiUrl.facilityParkers( facilityId ), getArgs() ); }

    static facilitySubscriptionList( facilityId: number )
        { return api<FacilitySubApi[]>( ApiUrl.facilitySubscriptionList( facilityId ), getArgs() ); }

    static facilityParkerDetails( facilityId: number, parkerId: number )
        { return api<ParkerDetailsApi>( ApiUrl.facilityParkerDetails( facilityId, parkerId ), getArgs() ); }

    static facilityParkerVehicleList( facilityId: number, parkerId: number )
        { return api2( ApiUrl.facilityParkerVehicleList( facilityId, parkerId ), getArgs(), parseArray( parseVehicleDetails ) ); }

    static facilityParkerInvite( facilityId: number, req: InviteParkerReqApi )
        { return api2<string, string>( ApiUrl.facilityParkerInvite( facilityId ), postArgs( req ), x => x ); }

    static facilitySubscriptionGet( facilityId: number, subscriptionId: number )
        { return api2( ApiUrl.facilitySubscriptionGet( facilityId, subscriptionId ), getArgs(), parseSubEntryForEdit ); }

    static facilitySubscriptionUpdate( facilityId: number, subscriptionId: number, req: SubscriptionUpdateReq )
        { return api2( ApiUrl.facilitySubscriptionUpdate( facilityId, subscriptionId ), postArgs( emitSubscriptionUpdateReq( req ) ), parseSubEntry ); }

    //facility big lists
    static facilityInvoiceList( facilityId: number )
        { return api2( ApiUrl.facilityInvoiceList( facilityId ), getArgs(), parseArray( parseFacilityInvoice ) ); }

    //plans
    static facilityPlanCreate( facilityId: number, req: PlanCreateReqApi )
        { return api2( ApiUrl.facilityPlanCreate( facilityId ), postArgs( req ), parsePlan ); }

    static facilityPlanList( id: number )
        { return api2( ApiUrl.facilityPlanList( id ), getArgs(), parseArray( parsePlan ) ); }

    static facilityPlanGet( facilityId: number, planId: number )
        { return api2( ApiUrl.facilityPlanGet( facilityId, planId ), getArgs(), parsePlanDetails ); }

    static facilityPlanUpdate( facilityId: number, planId: number, req: PlanUpdateReqApi )
        { return api<PlanApi>( ApiUrl.facilityPlanUpdate( facilityId, planId ), postArgs( req ) ); }

    //plan prices
    static facilityPlanPriceAdd ( facilityId: number, planId: number, req: PlanPriceAddReqApi) { return api<PlanPriceListRespApi>(ApiUrl.facilityPlanPriceCreate(facilityId, planId), postArgs(req)); }
    static facilityPlanPriceList( facilityId: number, planId: number ) { return api<PlanPriceListRespApi[]>(ApiUrl.facilityPlanPriceList(facilityId, planId), getArgs()); }

    //rates
    static facilityRateList   = ( facilityId: number ) => api<RateProgramApi[]>(ApiUrl.facilityRateList(facilityId), getArgs());
    static facilityRateGet    = ( facilityId: number, rateProgramId: number ) => api<RateProgramApi>(ApiUrl.facilityRateGet( facilityId, rateProgramId ), getArgs());
    static facilityRateCreate = ( facilityId: number,                        req: RateProgramDataApi ) => api<RateProgramApi>( ApiUrl.facilityRateCreate(facilityId),                postArgs(req) );
    static facilityRateUpdate = ( facilityId: number, rateProgramId: number, req: RateProgramDataApi ) => api<RateProgramApi>( ApiUrl.facilityRateUpdate(facilityId, rateProgramId), postArgs(req) );

    //parker
    static parkerUpdate( id: number, req: ParkerUpdateReq ) { return api2( ApiUrl.parkerUpdate( id ), postArgs( req ), parseParkerInfo ); }

    //stripe
    static stripeInit( facilityId: number ) { return api<ConnectInitResp>(   ApiUrl.stripeInit( facilityId ), postArgs( {} ) ); }
    static stripeLink( facilityId: number ) { return api<StripeLinkRespApi>( ApiUrl.stripeLink( facilityId ), getArgs() ); }

    //operator
    static opDetails() { return api<OperatorApi>( ApiUrl.opDetails(), getArgs() ); }

    //finance
    static orderList          = ( facilityId: number, parkerId: number )                    => api<OrderApi[]>     ( ApiUrl.facilityParkerOrderList(     facilityId, parkerId ), getArgs() );
    static orderOpenList      = ( facilityId: number, parkerId: number )                    => api<OrderDebtApi[]> ( ApiUrl.facilityParkerOrderOpenList( facilityId, parkerId ), getArgs() );
    static orderDetails       = ( facilityId: number, parkerId: number, orderId:   number ) => api2( ApiUrl.facilityParkerOrderDetails( facilityId, parkerId, orderId ), getArgs(), parseOrderDetails );

    static invoiceList        = ( facilityId: number, parkerId: number )                    => api2( ApiUrl.facilityParkerInvoiceList(   facilityId, parkerId ), getArgs(), parseArray( parseInvoice ) );
    static invoiceDetails     = ( facilityId: number, parkerId: number, invoiceId: number ) => api2( ApiUrl.facilityParkerInvoiceDetails( facilityId, parkerId, invoiceId ), getArgs(), parseInvoice );

    static paymentList        = ( facilityId: number, parkerId: number )                    => api<PaymentApi[]>   ( ApiUrl.facilityParkerPaymentList(   facilityId, parkerId ), getArgs() );
    static paymentDetails     = ( facilityId: number, parkerId: number, paymentId: number ) => api2( ApiUrl.facilityParkerPaymentDetails( facilityId, parkerId, paymentId ), getArgs(), parsePayment );

    static paymentCheckCreate = ( facilityId: number, parkerId: number, req: CheckCreateReqApi ) =>
        api<PaymentApi>( ApiUrl.facilityParkerCheckCreate( facilityId, parkerId ), postArgs( req ) );

    //reports
    static facilityAgingReport( facilityId: number ) { return api<ParkerAgingRecordApi[]>( ApiUrl.facilityReportAging( facilityId ), getArgs() ); }
}