import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { authAtom } from "./Data/Atoms";
import { Nav, Navbar } from "react-bootstrap";
import { APIProvider } from '@vis.gl/react-google-maps';
import { useEffect, useState } from "react";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { parseFacility, parseOperator } from "./Data/ApiParse";
import { Facility, Operator } from "./Data/ApiTypes";
import { FacilityListSearch } from "./Controls/FacilityListSearch";
import { PersonCircle } from "react-bootstrap-icons";

function UserMenu() {
    const nav = useNavigate();
    const [auth, setAuth] = useAtom( authAtom );
    if( auth.isLoggedIn === true
        && auth.account !== undefined ) {
        return <>
            <Nav.Link eventKey="user"
                      onClick={ () => nav( "/user" ) }
                      className="d-flex gap-2 align-items-center text-white" style={{ opacity: 1.0 }}>
                    {auth.account.emailAddress}
            </Nav.Link>
            <Nav.Link eventKey="logout"
                      onClick={() => setAuth( { isLoggedIn: false } )}
                      className="text-nowrap text-white fs-6" style={{ opacity: 1.0 }}>
                Log Out
            </Nav.Link>
        </>;
    }
    return <Navigate to="/login" />;
}

function TopMenu( { operatorName = "", facility = undefined }: { operatorName?: string, facility?: Facility } ) {
    const facilityName = facility?.name;
    // const
    return (
        <Navbar
            collapseOnSelect
            className="px-3 px-md-5 gap-0"
            expand="lg"
            bg="black"
            variant="dark">
            <Navbar.Brand href="/" className="fs-5 d-flex align-items-center gap-2 flex-shrink-1">
                <img src="/spotsync-logo.svg"
                     style={{ width: "1.5em", textOverflow: "ellipsis" }} />
                <span className="d-none d-sm-block">
                    <span className="fw-bold">Spot</span>Sync
                </span>
                {!!facilityName &&
                    <span className="d-none d-md-block">
                        {facilityName}
                    </span>
                }
            </Navbar.Brand>
            {!!facilityName &&
                <FacilityListSearch
                    className="px-0 pe-2 px-sm-2 flex-grow-1 d-print-none"
                    facility={facility}
                    placeholder="Search..."
                />
            }
            {!facilityName && <div className="flex-grow-1"></div>}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="flex-shrink-1 flex-grow-0 justify-content-end">
                <Nav>
                    <UserMenu />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export function AppLoggedIn() {
    const facilityIdStr = useParams().facilityId;
    const [operator, setOperator] = useState<Operator>();
    const [facility, setFacility] = useState<Facility>();

    useEffect( () => {
        Api.opDetails().then(
            res => processResult( res, val => setOperator( parseOperator( val ) ) )
        );
    }, [] );

    useEffect( () => {
        if( !facilityIdStr ) {
            setFacility( undefined );
            return;
        }
        Api.facilityDetails( parseInt( facilityIdStr ) )
            .then( res => processResult(
                res,
                val => setFacility( parseFacility( val ) )
            ) );
    }, [facilityIdStr] );

    return <APIProvider apiKey="AIzaSyBW7pTkR8xHrxMHXOQWGqXo4C518nbgNgo" libraries={["places"]}>
        <div id="main">
            <TopMenu operatorName={operator?.name}
                     facility={facility} />
            <div id="content">
                <Outlet />
            </div>
        </div>
    </APIProvider>;
}

export default AppLoggedIn;
