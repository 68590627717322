import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { useParamIds } from "./Data/Common";
import { BalanceStatusRenderer, colMoney, DataGrid } from "./Controls/DataGrid";
import { EnvelopePaper } from "react-bootstrap-icons";
import { PageBar, ToolButton } from "./Controls/PageBar";
import { Facility, FacilityInvoice } from "./Data/ApiTypes";
import { showDate, showInstantDate, showInstantDateTime } from "./Data/Date";
import { showOrderType } from "./Data/Finance";
import { Instant } from "js-joda";

export function FacilityInvoiceListPage() {
    const nav            = useNavigate();
    const { facilityId } = useParamIds();
    const invoices       = useLoaderData() as FacilityInvoice[];
    const facility       = useRouteLoaderData("facility") as Facility;

    const columns = useMemo<ColDef<FacilityInvoice>[]>( () => [
            // { field: "invoiceId", type: "rightAligned" },
            { field: "invoiceNumber", headerName: "Invoice #", type: "rightAligned" },            
            { field: "parker.name", headerName: "Parker" },
            // { field: "orderId", type: "rightAligned" },
            { field: "order.orderType",               headerName: "Invoice Type", valueFormatter: x => showOrderType( x.value ) },
            { field: "createdWhen",   type: "leftAligned",  headerName: "Created",    valueFormatter: x => showInstantDate( x.value, facility.timeZone ) },
            { field: "dueWhen",       type: "leftAligned",  headerName: "Due Date",   valueFormatter: x => showDate( x.value ) },
            // { field: "emailSentWhen", type: "leftAligned",  headerName: "Email Sent", valueFormatter: x => showInstantDate( x.value, facility.timeZone ) },
            { ...colMoney( "openingBalance" ),              headerName: "Opening" },
            { ...colMoney( "amountDue"      ),              headerName: "Amount Due" },
            { ...colMoney( "endingBalance"  ),              headerName: "Closing" },
            { ...colMoney( "order.balance.balance" ), headerName: "Status", cellRenderer: BalanceStatusRenderer }
        ], []
    );

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
    };

    const onRowClicked = ( event: any ) => {
        const invoice: FacilityInvoice = event.data;
        nav( `/facility/${facility.facilityId}/parker/${invoice.order.parkerId}/invoice/${invoice.invoiceId}` );
    };

    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "max-content 1fr 5px"}}>
                    <div className="d-flex align-items-center gap-2 ">
                        <PageBar title="Invoices">
                            <ToolButton onClick={() => nav(`/facility/${facilityId}/invoice/create`)}>
                                <EnvelopePaper color="maroon" /> Create Invoice
                            </ToolButton>
                        </PageBar>
                    </div>
                    <div style={{height: "100%", width: "100%"}}>
                        <DataGrid
                            onRowClicked={onRowClicked}
                            rowData={invoices}
                            columnDefs={columns}
                            onGridReady={onGridReady}
                            domLayout="autoHeight"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}