import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";

interface NameDescriptionModalProps {
    show:        boolean;
    setShow:     ( show: boolean            ) => void;
    onSave:      ( data: NameAndDescription ) => void;
    initialData: NameAndDescription;
}

export interface NameAndDescription {
    name:            string;
    description:     string;
    website:         string;
    emailAddress:    string;
    phoneNumber:     string;
    clearanceInches: number;
}

export default function NameDescriptionModal( { show, setShow, onSave, initialData }: NameDescriptionModalProps ) {
    const { register, formState: { errors }, handleSubmit } = useForm<NameAndDescription>( {
        defaultValues: initialData
    } );

    function submit( data: NameAndDescription ) {
        onSave( data );
        setShow( false );
    }

    return (
        <Modal show={show} centered onHide={() => setShow( false )}>
            <Form onSubmit={handleSubmit( submit )}>
                <Modal.Header closeButton>
                    <Modal.Title className="font-semibold">Edit Name and Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Input type="text"
                        label="Facility Name"
                        {...register( "name", { required: "Name is required" } )}
                        placeholder="Facility Name"
                        error={errors.name}
                    />

                    <Input as="textarea"
                        type="text"
                        label="Facility Description"
                        {...register( "description", { required: "Description is required" } )}
                        placeholder="Facility Description"
                        rows={3}
                        error={errors.description}
                    />

                    <Input
                        type="url"
                        label="Facility Website"
                        {...register( "website", { required: "Website is required" } )}
                        placeholder="https://example.com"
                        error={errors.description}
                    />

                    <Input
                        type="email"
                        label="Facility Email"
                        {...register( "emailAddress", { required: "Email is required" } )}
                        placeholder="thedon@gmail.com"
                        error={errors.emailAddress}
                    />

                    <Input
                        type="text"
                        label="Facility Phone"
                        {...register( "phoneNumber", {
                            required: "Facility Phone is required",
                            pattern: {
                                value: /^\d{3}-\d{3}-\d{4}$/,
                                message: "Phone number must be in format ###-###-####"
                            }
                        } )}
                        placeholder="555-555-5555"
                        error={errors.phoneNumber}
                    />

                    <Input
                        type="number"
                        label="Bar Height (in Inches)"
                        inputClassName="text-end"
                        placeholder=""
                        error={errors.clearanceInches}
                        {...register( "clearanceInches", {
                            required: "Bar Height is required",
                            valueAsNumber: true
                        } )} />

                    {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>}
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button className="flex-grow-1" onClick={() => setShow( false )}>Cancel</Button>
                    <Button className="flex-grow-1" type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}