//css
import './index.scss'
import './index.css';

//react
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, LoaderFunction, redirect, RouterProvider } from "react-router-dom";

//pages and components
import { AppLoggedIn                       } from './AppLoggedIn';
import { FacilityListPage                  } from "./FacilityListPage";
import { FacilityEditPage                  } from "./FacilityEditPage";
import { AppFacility                       } from "./AppFacility";
import { FacilityPlanListPage              } from "./FacilityPlanListPage";
import { FacilityPlanEditPage              } from "./FacilityPlanEditPage";
import { FacilityRateListPage                 } from "./FacilityRateListPage";
import { FacilityParkerListPage            } from "./FacilityParkerListPage";
import { FacilityInvoiceListPage    } from "./FacilityInvoiceListPage";
import { FacilitySchedulePage              } from "./FacilitySchedulePage";
import { FacilityRateDurationPage   } from "./FacilityRateDurationPage";
import { FacilityRateFixedPage      } from "./FacilityRateFixedPage";
import { FacilityRateCreatePage           } from "./FacilityRateCreatePage";
import { LogInPage                         } from "./LogInPage";
import { ForgotPasswordPage                } from "./ForgotPasswordPage";
import { AppUser                           } from "./AppUser";
import { UserProfilePage                   } from "./UserProfilePage";
import { UserAdvancedPage                  } from "./UserAdvancedPage";
import { FacilityParkerDetailsPage         } from "./FacilityParkerDetailsPage";
import { FacilityClosuresPage              } from "./FacilityClosuresPage";
import { FacilityInvoiceCreatePage           } from "./FacilityInvoiceCreatePage";
import { AppFacilityParker                 } from "./AppFacilityParker";
import { FacilityParkerInvitePage          } from "./FacilityParkerInvitePage";
import { FacilityParkerApplicationViewPage } from "./FacilityParkerViewApplication";
import { FacilityParkerOrderListPage    } from "./FacilityParkerOrderListPage";
import { FacilityParkerVehicleListPage         } from "./FacilityParkerVehicleListPage";
import { FacilityExample } from "./FacilityExample";
import { Facility, FacilitySub, Invoice, Order, OrderDetails, ParkerAgingRecord, ParkerDetails, ParkerListEntry, Plan, PlanDetails, RateProgram, StripeLinkResp, SubEntryForEdit } from "./Data/ApiTypes";
import { loadResult } from "./Data/Result";
import { Api, parseArray } from "./Data/Api";

import { parseFacility, parseParkerDetails, parseOperator, parseParkerListEntry, parseOrder, parsePayment,
     parseParkerAgingRecord, parseRateProgram, parseStripeLinkResp,
     parseFacilitySub,
     parseInvoice} from "./Data/ApiParse";

import { App } from "./App";
import { CustomFontSource, loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { getDefaultStore } from "jotai";
import { authAtom } from "./Data/Atoms";
import { ConnectPaymentsListPage } from "./ConnectPaymentsListPage";
import { ConnectAccountManagePage } from "./AccountManagePage";
import { AppOperator } from "./AppOperator";
import { OperatorDashboard } from "./OperatorDashboard";
import { ConnectPayoutsListPage } from "./ConnectPayoutsListPage";
import { FacilityListingPage } from "./FacilityListingPage";
import { FacilityOverviewPage } from "./FacilityOverviewPage";
import { Sandbox } from "./Sandbox";
import { FacilityParkerPaymentListPage } from "./FacilityParkerPaymentListPage";
import { FacilityPlanCreatePage } from "./FacilityPlanCreatePage";
import { FacilityReportAgingPage } from "./FacilityReportAgingPage";
import { paramIds } from "./Data/Common";
import { FacilityPlanDetailsPage } from "./FacilityPlanDetailsPage";
import { FacilityParkerVehicleDetailsPage } from "./FacilityParkerVehicleDetailsPage";
import { FacilityParkerSubscriptionEditPage } from "./FacilityParkerSubscriptionEditPage";
import { StaffInvitePage } from "./FacilityStaffInvitePage";
import { FacilityParkerOrderDetailsPage } from "./FacilityParkerOrderDetailsPage";
import { FacilityParkerPaymentDetailsPage } from "./FacilityParkerPaymentDetailsPage";
import { FacilityParkerInvoiceListPage } from "./FacilityParkerInvoiceListPage";
import { FacilityParkerInvoiceDetailsPage } from "./FacilityParkerInvoiceDetailsPage";
import { Vehicle } from "./Data/FakeData";
import { FacilityPlanSignPage } from "./FacilityPlanSignPage";
import { FacilityRateSignPage } from "./FacilityRateSignPage";

function identity<T>( arg: T ) { return arg; }
function unknown<T>(  arg: T ) { return undefined; }

const ldAuth: LoaderFunction = async( { params } ) => {
    return Api.amILoggedIn().then( res => loadResult( res,
        val => val,
        err => redirect( "/login" )
    ) );
}

const ldFacilities: LoaderFunction<Facility[]> = async( { params } ) => {
    return await Api.facilityList()
        .then( res => loadResult( res,
            res => res.map( parseFacility ),
            unknown ) );
}

export interface ExtendedFacility extends Facility {
    connectInstance: StripeConnectInstance;
    account:         StripeLinkResp;
}

const ldFacility: LoaderFunction<Facility> = async( { params } ) => {
    const { facilityId } = paramIds( params )

    const facility = await Api.facilityDetails( facilityId )
        .then( res => loadResult( res, parseFacility, unknown ) );

    const link = await Api.stripeLink( facilityId )
        .then( res => loadResult( res, parseStripeLinkResp, unknown ) );

    const auth = defaultStore.get( authAtom );
    if( !auth.isLoggedIn ) {
        return redirect( "/login" );
    }

    const fetchClientSecret = async () => {
        return loadResult( await Api.stripeInit( facilityId ),
            val => val.clientSecret,
            err => "Err!" );
    };

    const connectInstance = loadConnectAndInitialize( {
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
        fetchClientSecret: fetchClientSecret,
        appearance: {
            variables: {
                fontFamily:                     "silka",
                bodyMdFontWeight:               "400",
                bodySmFontWeight:               "400",
                labelMdFontWeight:              "300",
                labelSmFontWeight:              "300",
                fontSizeBase:                   "16px",
                spacingUnit:                    "9px",
                buttonBorderRadius:             "16px",
                colorPrimary:                   "#D61F84", //optional appearance param,
                buttonPrimaryColorText:         "#000000",
                buttonPrimaryColorBackground:   "#FFFFFF",
                buttonPrimaryColorBorder:       "#D61F84",
                buttonSecondaryColorBackground: "#FFFFFF",
                buttonSecondaryColorBorder:     "#D61F84",
                buttonSecondaryColorText:       "#000000",
            },
        },
        fonts: [silkaRegular, silkaBold],
    } );
    if( !facility || !link ) {
        return undefined;
    }
    const ret: ExtendedFacility = {
        ...facility,
        connectInstance: connectInstance,
        account:            link
     };
    return ret;
}

const ldAgingReport: LoaderFunction<ParkerAgingRecord[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityAgingReport( facilityId )
        .then( res => loadResult( res,
            res => res.map( parseParkerAgingRecord ),
            unknown )
        );
}

const ldPlans: LoaderFunction<Plan[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityPlanList( facilityId )
        .then( res => loadResult( res, val => val, unknown ) );
}

const ldRate: LoaderFunction<RateProgram> = async( { params } ) => {
    const { facilityId, rateId } = paramIds( params );
    return await Api.facilityRateGet( facilityId, rateId )
        .then( res => loadResult( res, parseRateProgram, unknown ) );
}

const ldFacilityParker: LoaderFunction<ParkerDetails> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return loadResult(
        await Api.facilityParkerDetails( facilityId, parkerId ),
        parseParkerDetails,
        unknown );
}

const ldFacilityInvoices: LoaderFunction<Invoice[]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    return await Api.facilityInvoiceList( facilityId )
        .then( res => loadResult( res, identity, unknown ) );
}

const silkaRegular: CustomFontSource = {
    family: "silka",
    src: "local('silka'), url(/fonts/silka-regular-webfont.woff) format('woff')",
    weight: "normal"
}

const silkaBold: CustomFontSource = {
    family: "silka",
    src: "local('silka'), url(/fonts/silka-bold-webfont.woff) format('woff')",
    weight: "bold"
}

const defaultStore = getDefaultStore();
const ldOperator: LoaderFunction<Facility> = async( { params } ) => {
    return Api.opDetails().then(
        res => loadResult( res, parseOperator, unknown ) );
}

const ldFacilityParkers: LoaderFunction<[ParkerListEntry[], FacilitySub[]]> = async( { params } ) => {
    const { facilityId } = paramIds( params )
    const parkers = await Api.facilityParkers( facilityId )
        .then( res => loadResult( res, res => res.map( parseParkerDetails ), unknown ) );

    const subs = await Api.facilitySubscriptionList( facilityId )
        .then( res => loadResult( res, res => res.map( parseFacilitySub ), unknown ) );

    return [parkers, subs];
}

const ldOrders: LoaderFunction<Order[]> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.orderList( facilityId, parkerId )
        .then( res => loadResult( res,
            res => res.map( parseOrder ),
            unknown )
    );
}

const ldOrderDetails: LoaderFunction<OrderDetails> = async( { params } ) => {
    const { facilityId, parkerId, orderId } = paramIds( params )
    return await Api.orderDetails( facilityId, parkerId, orderId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldInvoices: LoaderFunction<Invoice[]> = async ( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.invoiceList( facilityId, parkerId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldInvoiceDetails: LoaderFunction<Invoice> = async ( { params } ) => {
    const { facilityId, parkerId, invoiceId } = paramIds( params )
    return await Api.invoiceDetails( facilityId, parkerId, invoiceId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldPayments: LoaderFunction<Order[]> = async( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.paymentList( facilityId, parkerId )
        .then( res => loadResult( res, val => val.map( parsePayment ), unknown ) );
}

const ldPaymentDetails: LoaderFunction<Order[]> = async( { params } ) => {
    const { facilityId, parkerId, paymentId } = paramIds( params )
    return await Api.paymentDetails( facilityId, parkerId, paymentId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldPlanDetails: LoaderFunction<PlanDetails> = async( { params } ) => {
    const { facilityId, planId } = paramIds( params )
    return await Api.facilityPlanGet( facilityId, planId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldSubDetails: LoaderFunction<SubEntryForEdit> = async( { params } ) => {
    const { facilityId, subscriptionId } = paramIds( params )
    return await Api.facilitySubscriptionGet( facilityId, subscriptionId )
        .then( res => loadResult( res, identity, unknown ) );
}

const ldVehicles: LoaderFunction<Vehicle[]> = async ( { params } ) => {
    const { facilityId, parkerId } = paramIds( params )
    return await Api.facilityParkerVehicleList( facilityId, parkerId )
        .then( res => loadResult( res, val => val, unknown ) );
};

//based on the type of the account we'll default to facilities list with other things
const router = createBrowserRouter( [
    { path: "/sandbox", element: <Sandbox />, },
    { element: <App />, children: [
        { path: "/login",  element: <LogInPage          /> },
        { path: "/forgot", element: <ForgotPasswordPage /> },
    ] },
    { path: "/", element: <App />, loader: ldAuth, children: [
        { element: <AppLoggedIn />, children: [
            { path: "/operator",                          element: <AppOperator />, loader: ldOperator, children: [
                { path: "/operator/invite",                        element: <StaffInvitePage />, },
                { path: "/operator/",                     element: <OperatorDashboard        />, },
                { path: "/operator/profile",              element: <OperatorDashboard        />, },
                { path: "/operator/account",              element: <ConnectAccountManagePage />, },
                { path: "/operator/facility/create",      element: <FacilityEditPage     /> },
            ] },
            { path: "/",                                  element: <FacilityListPage     />, loader: ldFacilities },            
            //user stuff
            { path: "/user",                              element: <AppUser />, children: [
                    { path: "/user",                          element: <UserProfilePage         /> },
                    { path: "/user/profile",                  element: <UserProfilePage         /> },
                    { path: "/user/advanced",                 element: <UserAdvancedPage        /> },
                ] },
            { path: "/facility",                         element: <FacilityListPage     /> },                
            { path: "/facility/example",                 element: <FacilityExample      /> },
            { path: "/facility/:facilityId",             element: <AppFacility          />, loader: ldFacility, id: "facility", children: [
                { path: "/facility/:facilityId",         element: <FacilityOverviewPage              /> },
                { path: "overview",                      element: <FacilityOverviewPage              /> },
                { path: "payments",                      element: <ConnectPaymentsListPage           /> },
                { path: "payouts",                       element: <ConnectPayoutsListPage            /> },
                { path: "listing",                       element: <FacilityListingPage               /> },
                { path: "schedule",                      element: <FacilitySchedulePage              /> },
                { path: "closure",                       element: <FacilityClosuresPage              /> },

                { path: "parker",                        element: <FacilityParkerListPage            />, loader: ldFacilityParkers },
                { path: "parker/active",                 element: <FacilityParkerListPage            /> },
                { path: "parker/applied",                element: <FacilityParkerListPage            /> },
                { path: "parker/invited",                element: <FacilityParkerListPage            /> },
                { path: "parker/invite",                 element: <FacilityParkerInvitePage          />, loader: ldPlans },
                { path: "parker/invite/:parkerId",       element: <FacilityParkerApplicationViewPage /> },

                { path: "plan",                          element: <FacilityPlanListPage              />, loader: ldPlans       },
                { path: "plan/create",                   element: <FacilityPlanCreatePage            />, loader: ldFacility    },
                { path: "plan/:planId",                  element: <FacilityPlanDetailsPage           />, loader: ldPlanDetails },
                { path: "plan/:planId/edit",             element: <FacilityPlanEditPage              />, loader: ldPlanDetails },
                { path: "plan/:planId/sign",             element: <FacilityPlanSignPage              />, loader: ldPlanDetails },

                { path: "rate",                          element: <FacilityRateListPage              />, loader: ldFacility },
                { path: "rate/sign",                     element: <FacilityRateSignPage              />, loader: ldFacility },
                { path: "rate/:rateId",                  element: <FacilityRateDurationPage          />, loader: ldRate },

                { path: "rate/create",                   element: <FacilityRateCreatePage            /> },
                { path: "rate/create/fixed",             element: <FacilityRateFixedPage             /> },
                { path: "rate/create/duration",          element: <FacilityRateDurationPage          /> },

                //reports
                { path: "report/aging",                  element: <FacilityReportAgingPage           />, loader: ldAgingReport },

                //invoice
                { path: "invoice",                       element: <FacilityInvoiceListPage             />, loader: ldFacilityInvoices },
                { path: "invoice/create",                element: <FacilityInvoiceCreatePage           /> },
            ] },
            { path: "/facility/:facilityId/parker/:parkerId",     element: <AppFacilityParker          />, loader: ldFacilityParker, id: "parker", children: [
                { path: "/facility/:facilityId/parker/:parkerId", element: <FacilityParkerDetailsPage  />, loader: ldFacilityParker },
                { path: "overview",                      element: <FacilityParkerDetailsPage           />, loader: ldFacilityParker },
                { path: "plan/:subscriptionId",          element: <FacilityParkerSubscriptionEditPage  />, loader: ldSubDetails },
                { path: "order",                         element: <FacilityParkerOrderListPage         />, loader: ldOrders },
                { path: "order/:orderId",                element: <FacilityParkerOrderDetailsPage      />, loader: ldOrderDetails },
                { path: "invoice/",                      element: <FacilityParkerInvoiceListPage       />, loader: ldInvoices },
                { path: "invoice/:invoiceId",            element: <FacilityParkerInvoiceDetailsPage    />, loader: ldInvoiceDetails },
                { path: "payment",                       element: <FacilityParkerPaymentListPage       />, loader: ldPayments },
                { path: "payment/:paymentId",            element: <FacilityParkerPaymentDetailsPage    />, loader: ldPaymentDetails },
                { path: "vehicle",                       element: <FacilityParkerVehicleListPage       />, loader: ldVehicles },
                { path: "vehicle/:vehicleId",            element: <FacilityParkerVehicleDetailsPage    />, loader: ldVehicles }
            ] }
        ] }
    ]
} ] );

const root = ReactDOM.createRoot(
    document.getElementById( 'root' ) as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
