import { Col, Container, Row } from "react-bootstrap";
import { Button } from "./Controls/Button";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useParamIds } from "./Data/Common";
import { showDuration } from "./Data/Date";
import { showMoneyShort } from "./Data/Money";
import { Facility, RateProgram } from "./Data/ApiTypes";
import { rateProgramType } from "./Data/RateProgram";
import { PencilFill, QrCode } from "react-bootstrap-icons";
import { PageBar, ToolButton } from "./Controls/PageBar";

export function FacilityRateListPage() {
    const nav = useNavigate();
    const { facilityId } = useParamIds();
    const facility = useLoaderData() as Facility;
    return <Container>
        <Row className="justify-content-center">
            <Col xl="6" xxl="5" className="mb-2">
                <PageBar title="Hourly Rates">
                    <ToolButton onClick={() => nav( `/facility/${facilityId}/rate/sign` )}>
                        <QrCode color="maroon" /> View QR Code
                    </ToolButton>
                </PageBar>
                {facility.hourlyRates?.map( prog =>
                    <div key={prog.rateProgramId}>
                        <div className="d-flex-align-center gap-2 mb-2">
                            <span className="fs-5 fw-bold">
                                {prog.name}
                            </span>
                            <Button variant="sm"
                                className="d-flex-align-center"
                                style={{ padding: "1px 0.5em 1px 0.5em" }}
                                onClick={() => nav( `/facility/${facilityId}/rate/${prog.rateProgramId}` )}>
                                <PencilFill color="maroon" /> Edit Rate
                            </Button>
                        </div>
                        <RateProgramTable prog={prog} />
                    </div> )}
                <div className="mt-2">
                    <Button className="w-100" onClick={() => nav( `/facility/${facilityId}/rate/create` )}>
                        Create New Rate
                    </Button>
                </div>
            </Col>
        </Row>
    </Container>;
}

interface RateProgramTableProps {
    prog: RateProgram;
}

export function RateProgramTable( { prog }: RateProgramTableProps ) {
    return <div className="rounded-4 mb-3 overflow-hidden border"
            style={{ display:             "grid",
                     gridTemplateColumns: "1fr min-content 1fr 1fr" }}>
        <div className="d-grid fw-semibold bg-light border-bottom"
                style={{ gridColumn:          "1 / -1",
                        gridTemplateColumns: "subgrid" }}>
            <div className="p-2 text-center border-right"
                    style={{ gridColumn: "1 / 4" }}>
                {rateProgramType( prog )}
            </div>
            <div className="p-2 text-center">
                Price
            </div>
        </div>

        {/* rows */}
        {prog?.entries.map( ( rate, i ) => {
            const [upTo, unit] = showDuration( rate.upTo ).split( " " );
            return <div key={i} style={{
                display: "grid",
                gridColumn: "1 / -1",
                gridTemplateColumns: "subgrid",
                borderBottom: i < prog.entries.length - 1 ? "1px solid #dee2e6" : "none"
            }}>
                <div style={{
                    padding: "0.5rem",
                    textAlign: "end",
                }}>
                    Up to
                </div>
                <div style={{
                    padding: "0.5rem",
                    textAlign: "right",
                }}>
                    {upTo}
                </div>
                <div style={{
                    padding: "0.5rem",
                    textAlign: "left",
                    borderRight: "1px solid #dee2e6"
                }}>
                    {unit}
                </div>
                <div style={{
                    padding: "0.5rem",
                    textAlign: "center"
                }}>
                    {showMoneyShort( rate.rate )}
                </div>
            </div>
        } )}
    </div>;
}