import { OrderType } from "./ApiTransport"; 
import { PaymentType } from "./ApiTransport"; 
import { OrderInitiator } from "./ApiTransport"; 
import { OrderBalanceType } from "./ApiTransport"; 
import { SubscriptionStatus } from "./ApiTransport"; 
import { VehicleLinkType } from "./ApiTransport"; 
import { EntityType } from "./ApiTransport"; 
import { StaffRole } from "./ApiTransport"; import { OperatorApi } from "./ApiTransport"; 
import { FacilityInfoApi } from "./ApiTransport"; 
import { UsAddressApi } from "./ApiTransport"; 
import { CoordinatesApi } from "./ApiTransport"; 
import { OrderDebtApi } from "./ApiTransport"; 
import { VehicleApi } from "./ApiTransport"; 
import { RateProgramApi } from "./ApiTransport"; 
import { RateEntryApi } from "./ApiTransport"; 
import { FacilityImageApi } from "./ApiTransport"; 
import { ListedPlanApi } from "./ApiTransport"; 
import { OrderApi } from "./ApiTransport"; 
import { OrderDetailsApi } from "./ApiTransport"; 
import { OrderBalanceApi } from "./ApiTransport"; 
import { InvoiceApi } from "./ApiTransport"; 
import { PaymentApi } from "./ApiTransport"; 
import { ApplyPaymentApi } from "./ApiTransport"; 
import { OrderInfoApi } from "./ApiTransport"; 
import { PaymentChangeApi } from "./ApiTransport"; 
import { LogInReqApi } from "./ApiTransport"; 
import { ChangeNameReqApi } from "./ApiTransport"; 
import { ChangePhoneReqApi } from "./ApiTransport"; 
import { InviteStaffReqApi } from "./ApiTransport"; 
import { StripeLinkRespApi } from "./ApiTransport"; 
import { ChangePasswordReqApi } from "./ApiTransport"; 
import { ForgotPasswordReqApi } from "./ApiTransport"; 
import { SearchResultApi } from "./ApiTransport"; 
import { PlanCreateReqApi } from "./ApiTransport"; 
import { PlanUpdateReqApi } from "./ApiTransport"; 
import { PlanPriceAddReqApi } from "./ApiTransport"; 
import { PlanPriceListRespApi } from "./ApiTransport"; 
import { ReservationApi } from "./ApiTransport"; 
import { SubscriptionApi } from "./ApiTransport"; 
import { SubscriptionEntryApi } from "./ApiTransport"; 
import { PlanInfoApi } from "./ApiTransport"; 
import { FacilityApi } from "./ApiTransport"; 
import { ParkerApi } from "./ApiTransport"; 
import { ParkerListEntryApi } from "./ApiTransport"; 
import { ParkerDetailsApi } from "./ApiTransport"; 
import { CheckCreateReqApi } from "./ApiTransport"; 
import { PmtApplyApi } from "./ApiTransport"; 
import { PlanApi } from "./ApiTransport"; 
import { PlanDetailsApi } from "./ApiTransport"; 
import { PlanChangeApi } from "./ApiTransport"; 
import { PlanPriceInfoApi } from "./ApiTransport"; 
import { PlanPriceDetailsApi } from "./ApiTransport"; 
import { InviteParkerReqApi } from "./ApiTransport"; 
import { FacilityCreateReqApi } from "./ApiTransport"; 
import { FacilityUpdateReqApi } from "./ApiTransport"; 
import { ParkerAgingRecordApi } from "./ApiTransport"; 
import { ParkerInfoApi } from "./ApiTransport"; 
import { RateProgramDataApi } from "./ApiTransport"; 
import { RateEntryDataApi } from "./ApiTransport"; 
import { SubscriberInfoApi } from "./ApiTransport"; 
import { SubscriptionUpdateReqApi } from "./ApiTransport"; 
import { SubEntryApi } from "./ApiTransport"; 
import { SubEntryForEditApi } from "./ApiTransport"; 
import { AccessPeriodInfoApi } from "./ApiTransport"; 
import { SubscriptionParkerApi } from "./ApiTransport"; 
import { ParkerUpdateReqApi } from "./ApiTransport"; 
import { SubListEntryApi } from "./ApiTransport"; 
import { PlanInfoBasicApi } from "./ApiTransport"; 
import { FacilitySubApi } from "./ApiTransport"; 
import { StaffApi } from "./ApiTransport"; 
import { DeleteConfirmApi } from "./ApiTransport"; 
import { ChangeEmailReqApi } from "./ApiTransport"; 
import { VehicleDetailsApi } from "./ApiTransport"; 
import { VehicleLinkApi } from "./ApiTransport"; 
import { FacilityInvoiceApi } from "./ApiTransport"; import { Operator } from "./ApiTypes"; 
import { FacilityInfo } from "./ApiTypes"; 
import { UsAddress } from "./ApiTypes"; 
import { Coordinates } from "./ApiTypes"; 
import { OrderDebt } from "./ApiTypes"; 
import { Vehicle } from "./ApiTypes"; 
import { RateProgram } from "./ApiTypes"; 
import { RateEntry } from "./ApiTypes"; 
import { FacilityImage } from "./ApiTypes"; 
import { ListedPlan } from "./ApiTypes"; 
import { Order } from "./ApiTypes"; 
import { OrderDetails } from "./ApiTypes"; 
import { OrderBalance } from "./ApiTypes"; 
import { Invoice } from "./ApiTypes"; 
import { Payment } from "./ApiTypes"; 
import { ApplyPayment } from "./ApiTypes"; 
import { OrderInfo } from "./ApiTypes"; 
import { PaymentChange } from "./ApiTypes"; 
import { LogInReq } from "./ApiTypes"; 
import { ChangeNameReq } from "./ApiTypes"; 
import { ChangePhoneReq } from "./ApiTypes"; 
import { InviteStaffReq } from "./ApiTypes"; 
import { StripeLinkResp } from "./ApiTypes"; 
import { ChangePasswordReq } from "./ApiTypes"; 
import { ForgotPasswordReq } from "./ApiTypes"; 
import { SearchResult } from "./ApiTypes"; 
import { PlanCreateReq } from "./ApiTypes"; 
import { PlanUpdateReq } from "./ApiTypes"; 
import { PlanPriceAddReq } from "./ApiTypes"; 
import { PlanPriceListResp } from "./ApiTypes"; 
import { Reservation } from "./ApiTypes"; 
import { Subscription } from "./ApiTypes"; 
import { SubscriptionEntry } from "./ApiTypes"; 
import { PlanInfo } from "./ApiTypes"; 
import { Facility } from "./ApiTypes"; 
import { Parker } from "./ApiTypes"; 
import { ParkerListEntry } from "./ApiTypes"; 
import { ParkerDetails } from "./ApiTypes"; 
import { CheckCreateReq } from "./ApiTypes"; 
import { PmtApply } from "./ApiTypes"; 
import { Plan } from "./ApiTypes"; 
import { PlanDetails } from "./ApiTypes"; 
import { PlanChange } from "./ApiTypes"; 
import { PlanPriceInfo } from "./ApiTypes"; 
import { PlanPriceDetails } from "./ApiTypes"; 
import { InviteParkerReq } from "./ApiTypes"; 
import { FacilityCreateReq } from "./ApiTypes"; 
import { FacilityUpdateReq } from "./ApiTypes"; 
import { ParkerAgingRecord } from "./ApiTypes"; 
import { ParkerInfo } from "./ApiTypes"; 
import { RateProgramData } from "./ApiTypes"; 
import { RateEntryData } from "./ApiTypes"; 
import { SubscriberInfo } from "./ApiTypes"; 
import { SubscriptionUpdateReq } from "./ApiTypes"; 
import { SubEntry } from "./ApiTypes"; 
import { SubEntryForEdit } from "./ApiTypes"; 
import { AccessPeriodInfo } from "./ApiTypes"; 
import { SubscriptionParker } from "./ApiTypes"; 
import { ParkerUpdateReq } from "./ApiTypes"; 
import { SubListEntry } from "./ApiTypes"; 
import { PlanInfoBasic } from "./ApiTypes"; 
import { FacilitySub } from "./ApiTypes"; 
import { Staff } from "./ApiTypes"; 
import { DeleteConfirm } from "./ApiTypes"; 
import { ChangeEmailReq } from "./ApiTypes"; 
import { VehicleDetails } from "./ApiTypes"; 
import { VehicleLink } from "./ApiTypes"; 
import { FacilityInvoice } from "./ApiTypes"; 
import { cents } from "./Money";
import { createDuration, createLocalDateTime, createLocalTime, createLocalDate, createInstant } from "./ApiConverters";
import { Duration } from "js-joda";export function parseOperator( o: OperatorApi ): Operator {
    return {
        operatorId: o.operatorId,
        name: o.name,
        address: parseUsAddress( o.address ),
        emailAddress: o.emailAddress,
        phoneNumber: o.phoneNumber,
        website: o.website,
        topTitle: o.topTitle,
        topText: o.topText,
        bottomLeftTitle: o.bottomLeftTitle,
        bottomLeftText: o.bottomLeftText,
        bottomRightTitle: o.bottomRightTitle,
        bottomRightText: o.bottomRightText,
    }
}

export function parseFacilityInfo( f: FacilityInfoApi ): FacilityInfo {
    return {
        facilityId: f.facilityId,
        operatorId: f.operatorId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: parseUsAddress( f.address ),
        coordinates: parseCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
        phoneNumber: f.phoneNumber,
        emailAddress: f.emailAddress,
        clearanceInches: f.clearanceInches,
    }
}

export function parseUsAddress( u: UsAddressApi ): UsAddress {
    return {
        addressLine1: u.addressLine1,
        addressLine2: u.addressLine2,
        city: u.city,
        state: u.state,
        zipCode: u.zipCode,
    }
}

export function parseCoordinates( c: CoordinatesApi ): Coordinates {
    return {
        lat: c.lat,
        lng: c.lng,
    }
}

export function parseOrderDebt( o: OrderDebtApi ): OrderDebt {
    return {
        orderId: o.orderId,
        createdWhen: createInstant( o.createdWhen ),
        orderTotal: cents( o.orderTotal ),
        paid: cents( o.paid ),
        unpaid: cents( o.unpaid ),
    }
}

export function parseVehicle( v: VehicleApi ): Vehicle {
    return {
        vehicleId: v.vehicleId,
        make: v.make,
        model: v.model,
        color: v.color,
        stateCode: v.stateCode,
        licensePlateNumber: v.licensePlateNumber,
    }
}

export function parseRateProgram( r: RateProgramApi ): RateProgram {
    return {
        rateProgramId: r.rateProgramId,
        facilityId: r.facilityId,
        name: r.name,
        start: createLocalDateTime( r.start ),
        end: r.end != null ? createLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? createLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? createLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? createLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? createLocalTime( r.outBefore ) : null,
        entries: r.entries.map( el => parseRateEntry( el ) ),
    }
}

export function parseRateEntry( r: RateEntryApi ): RateEntry {
    return {
        rateEntryId: r.rateEntryId,
        rateProgramId: r.rateProgramId,
        upTo: Duration.ofMinutes( r.upTo ),
        rate: cents( r.rate ),
    }
}

export function parseFacilityImage( f: FacilityImageApi ): FacilityImage {
    return {
        facilityImageId: f.facilityImageId,
        storedImageId: f.storedImageId,
        orderId: f.orderId,
        caption: f.caption,
        altText: f.altText,
    }
}

export function parseListedPlan( l: ListedPlanApi ): ListedPlan {
    return {
        planId: l.planId,
        planEntryId: l.planEntryId,
        name: l.name,
        description: l.description,
        accessHours: l.accessHours,
        dayOfMonthInvoice: l.dayOfMonthInvoice,
        dayOfMonthDue: l.dayOfMonthDue,
        dayOfMonthLate: l.dayOfMonthLate,
        price: cents( l.price ),
    }
}

export function parseOrder( o: OrderApi ): Order {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: cents( o.price ),
        createdWhen: createInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        type: o.type,
        facility: parseFacilityInfo( o.facility ),
        submittedWhen: o.submittedWhen != null ? createInstant( o.submittedWhen ) : null,
        balance: parseOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function parseOrderDetails( o: OrderDetailsApi ): OrderDetails {
    return {
        order: parseOrder( o.order ),
        balanceChanges: o.balanceChanges.map( el => parseOrderBalance( el ) ),
        summary: o.summary,
    }
}

export function parseOrderBalance( o: OrderBalanceApi ): OrderBalance {
    return {
        orderBalanceId: o.orderBalanceId,
        type: o.type,
        orderId: o.orderId,
        createdWhen: createInstant( o.createdWhen ),
        debt: cents( o.debt ),
        paid: cents( o.paid ),
        balance: cents( o.balance ),
        totalDebt: cents( o.totalDebt ),
        totalPaid: cents( o.totalPaid ),
        reason: o.reason != null ? o.reason : null,
        paymentId: o.paymentId != null ? o.paymentId : null,
    }
}

export function parseInvoice( i: InvoiceApi ): Invoice {
    return {
        invoiceId: i.invoiceId,
        invoiceNumber: i.invoiceNumber,
        orderId: i.orderId,
        createdWhen: createInstant( i.createdWhen ),
        dueWhen: createLocalDate( i.dueWhen ),
        openingBalance: cents( i.openingBalance ),
        amountDue: cents( i.amountDue ),
        endingBalance: cents( i.endingBalance ),
        emailSentWhen: i.emailSentWhen != null ? createInstant( i.emailSentWhen ) : null,
        facility: parseFacilityInfo( i.facility ),
        order: parseOrderInfo( i.order ),
        parker: parseParkerInfo( i.parker ),
    }
}

export function parsePayment( p: PaymentApi ): Payment {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        facility: parseFacilityInfo( p.facility ),
        parkerId: p.parkerId,
        type: p.type,
        amount: cents( p.amount ),
        balance: cents( p.balance ),
        remaining: cents( p.remaining ),
        paymentMethod: p.paymentMethod,
        changes: p.changes.map( el => parsePaymentChange( el ) ),
        applications: p.applications.map( el => parseApplyPayment( el ) ),
        createdWhen: createInstant( p.createdWhen ),
    }
}

export function parseApplyPayment( a: ApplyPaymentApi ): ApplyPayment {
    return {
        orderBalanceId: a.orderBalanceId,
        type: a.type,
        facilityId: a.facilityId,
        parkerId: a.parkerId,
        orderId: a.orderId,
        debt: cents( a.debt ),
        paid: cents( a.paid ),
        balance: cents( a.balance ),
        totalDebt: cents( a.totalDebt ),
        totalPaid: cents( a.totalPaid ),
    }
}

export function parseOrderInfo( o: OrderInfoApi ): OrderInfo {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: cents( o.price ),
        createdWhen: createInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        orderType: o.orderType,
        submittedWhen: o.submittedWhen != null ? createInstant( o.submittedWhen ) : null,
        balance: parseOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function parsePaymentChange( p: PaymentChangeApi ): PaymentChange {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        parkerId: p.parkerId,
        type: p.type,
        amount: cents( p.amount ),
        balance: cents( p.balance ),
        createdWhen: createInstant( p.createdWhen ),
    }
}

export function parseLogInReq( l: LogInReqApi ): LogInReq {
    return {
        emailAddress: l.emailAddress,
        password: l.password,
    }
}

export function parseChangeNameReq( c: ChangeNameReqApi ): ChangeNameReq {
    return {
        name: c.name,
    }
}

export function parseChangePhoneReq( c: ChangePhoneReqApi ): ChangePhoneReq {
    return {
        phoneNumber: c.phoneNumber,
    }
}

export function parseInviteStaffReq( i: InviteStaffReqApi ): InviteStaffReq {
    return {
        role: i.role,
        name: i.name,
        emailAddress: i.emailAddress,
        password: i.password,
        note: i.note,
    }
}

export function parseStripeLinkResp( s: StripeLinkRespApi ): StripeLinkResp {
    return {
        isOnboarded: s.isOnboarded,
        connectLink: s.connectLink != null ? s.connectLink : null,
    }
}

export function parseChangePasswordReq( c: ChangePasswordReqApi ): ChangePasswordReq {
    return {
        currentPassword: c.currentPassword,
        newPassword: c.newPassword,
    }
}

export function parseForgotPasswordReq( f: ForgotPasswordReqApi ): ForgotPasswordReq {
    return {
        emailAddress: f.emailAddress,
    }
}

export function parseSearchResult( s: SearchResultApi ): SearchResult {
    return {
        searchResultId: s.searchResultId,
        type: s.type,
        id: s.id,
        name: s.name,
        parkerId: s.parkerId != null ? s.parkerId : null,
    }
}

export function parsePlanCreateReq( p: PlanCreateReqApi ): PlanCreateReq {
    return {
        facilityId: p.facilityId,
        name: p.name,
        accessHours: p.accessHours,
        description: p.description,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        initialPrice: p.initialPrice,
        terms: p.terms,
    }
}

export function parsePlanUpdateReq( p: PlanUpdateReqApi ): PlanUpdateReq {
    return {
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        note: p.note,
        terms: p.terms,
    }
}

export function parsePlanPriceAddReq( p: PlanPriceAddReqApi ): PlanPriceAddReq {
    return {
        name: p.name,
        price: p.price,
    }
}

export function parsePlanPriceListResp( p: PlanPriceListRespApi ): PlanPriceListResp {
    return {
        planPriceId: p.planPriceId,
        name: p.name,
        price: p.price,
    }
}

export function parseReservation( r: ReservationApi ): Reservation {
    return {
        reservationId: r.reservationId,
        facilityId: r.facilityId,
        parkerId: r.parkerId,
        start: createLocalDateTime( r.start ),
        end: createLocalDateTime( r.end ),
        actualStart: createInstant( r.actualStart ),
        actualEnd: createInstant( r.actualEnd ),
        duration: Duration.ofMinutes( r.duration ),
        rateProgramId: r.rateProgramId,
        price: cents( r.price ),
        createdWhen: createInstant( r.createdWhen ),
        facility: parseFacilityInfo( r.facility ),
        rateProgram: parseRateProgram( r.rateProgram ),
    }
}

export function parseSubscription( s: SubscriptionApi ): Subscription {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        status: s.status,
        start: createLocalDate( s.start ),
        currentEntry: parseSubscriptionEntry( s.currentEntry ),
        history: s.history.map( el => parseSubscriptionEntry( el ) ),
        facility: parseFacilityInfo( s.facility ),
        accessPeriods: s.accessPeriods.map( el => parseAccessPeriodInfo( el ) ),
        parkers: s.parkers.map( el => parseSubscriptionParker( el ) ),
        vehicles: s.vehicles.map( el => parseVehicle( el ) ),
        paymentMethod: s.paymentMethod != null ? s.paymentMethod : null,
    }
}

export function parseSubscriptionEntry( s: SubscriptionEntryApi ): SubscriptionEntry {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        start: createLocalDate( s.start ),
        end: s.end != null ? createLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        accessPeriods: s.accessPeriods.map( el => parseAccessPeriodInfo( el ) ),
        price: cents( s.price ),
        plan: parsePlanInfo( s.plan ),
    }
}

export function parsePlanInfo( p: PlanInfoApi ): PlanInfo {
    return {
        planId: p.planId,
        facilityId: p.facilityId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
    }
}

export function parseFacility( f: FacilityApi ): Facility {
    return {
        facilityId: f.facilityId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: parseUsAddress( f.address ),
        coordinates: parseCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
        physicalCapacity: f.physicalCapacity,
        enforcedCapacity: f.enforcedCapacity,
        clearanceInches: f.clearanceInches,
        website: f.website,
        emailAddress: f.emailAddress,
        phoneNumber: f.phoneNumber,
        images: f.images.map( el => parseFacilityImage( el ) ),
        hourlyRates: f.hourlyRates.map( el => parseRateProgram( el ) ),
        listedPlans: f.listedPlans.map( el => parseListedPlan( el ) ),
    }
}

export function parseParker( p: ParkerApi ): Parker {
    return {
        parkerId: p.parkerId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        note: p.note,
    }
}

export function parseParkerListEntry( p: ParkerListEntryApi ): ParkerListEntry {
    return {
        parkerId: p.parkerId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber,
        note: p.note,
        balance: cents( p.balance ),
        monthly: cents( p.monthly ),
    }
}

export function parseParkerDetails( p: ParkerDetailsApi ): ParkerDetails {
    return {
        parkerId: p.parkerId,
        name: p.name,
        note: p.note,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        balance: cents( p.balance ),
        subscriptions: p.subscriptions.map( el => parseSubListEntry( el ) ),
        reservations: p.reservations.map( el => parseReservation( el ) ),
        vehicles: p.vehicles.map( el => parseVehicle( el ) ),
    }
}

export function parseCheckCreateReq( c: CheckCreateReqApi ): CheckCreateReq {
    return {
        checkDate: createLocalDate( c.checkDate ),
        checkNumber: c.checkNumber,
        checkMemo: c.checkMemo,
        amount: c.amount,
    }
}

export function parsePmtApply( p: PmtApplyApi ): PmtApply {
    return {
        orderId: p.orderId,
        amount: cents( p.amount ),
    }
}

export function parsePlan( p: PlanApi ): Plan {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        planPrice: p.planPrice != null ? parsePlanPriceInfo( p.planPrice ) : null,
        prices: p.prices.map( el => parsePlanPriceDetails( el ) ),
        note: p.note,
    }
}

export function parsePlanDetails( p: PlanDetailsApi ): PlanDetails {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        terms: p.terms,
        planPrice: p.planPrice != null ? parsePlanPriceInfo( p.planPrice ) : null,
        prices: p.prices.map( el => parsePlanPriceDetails( el ) ),
        history: p.history.map( el => parsePlanChange( el ) ),
    }
}

export function parsePlanChange( p: PlanChangeApi ): PlanChange {
    return {
        planEntryId: p.planEntryId,
        planId: p.planId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
        planPriceId: p.planPriceId != null ? p.planPriceId : null,
        planPrice: p.planPrice != null ? parsePlanPriceInfo( p.planPrice ) : null,
        createdWhen: createInstant( p.createdWhen ),
        createdByStaffId: p.createdByStaffId,
        note: p.note,
    }
}

export function parsePlanPriceInfo( p: PlanPriceInfoApi ): PlanPriceInfo {
    return {
        planPriceId: p.planPriceId,
        planId: p.planId,
        name: p.name,
        price: cents( p.price ),
        createdByStaffId: p.createdByStaffId,
        createdWhen: createInstant( p.createdWhen ),
    }
}

export function parsePlanPriceDetails( p: PlanPriceDetailsApi ): PlanPriceDetails {
    return {
        planPriceId: p.planPriceId,
        planId: p.planId,
        name: p.name,
        price: cents( p.price ),
        createdByStaffId: p.createdByStaffId,
        createdWhen: createInstant( p.createdWhen ),
        subscribers: p.subscribers.map( el => parseSubscriberInfo( el ) ),
    }
}

export function parseInviteParkerReq( i: InviteParkerReqApi ): InviteParkerReq {
    return {
        name: i.name,
        emailAddress: i.emailAddress,
        phoneNumber: i.phoneNumber != null ? i.phoneNumber : null,
        planId: i.planId,
        planPriceId: i.planPriceId,
        quantity: i.quantity,
        message: i.message,
    }
}

export function parseFacilityCreateReq( f: FacilityCreateReqApi ): FacilityCreateReq {
    return {
        name: f.name,
        address: parseUsAddress( f.address ),
        description: f.description,
        instructions: f.instructions,
        postedCapacity: f.postedCapacity,
        enforcedCapacity: f.enforcedCapacity,
        website: f.website,
        emailAddress: f.emailAddress,
        phoneNumber: f.phoneNumber,
        clearanceInches: f.clearanceInches,
        imageThumb: f.imageThumb,
        bannerImage: f.bannerImage,
    }
}

export function parseFacilityUpdateReq( f: FacilityUpdateReqApi ): FacilityUpdateReq {
    return {
        facilityId: f.facilityId,
        name: f.name,
        address: parseUsAddress( f.address ),
        description: f.description,
        instructions: f.instructions,
        postedCapacity: f.postedCapacity,
        enforcedCapacity: f.enforcedCapacity,
        clearanceInches: f.clearanceInches,
        website: f.website,
        emailAddress: f.emailAddress,
        phoneNumber: f.phoneNumber,
    }
}

export function parseParkerAgingRecord( p: ParkerAgingRecordApi ): ParkerAgingRecord {
    return {
        parker: parseParkerInfo( p.parker ),
        orderId: p.orderId,
        days30: p.days30,
        days60: p.days60,
        days90: p.days90,
    }
}

export function parseParkerInfo( p: ParkerInfoApi ): ParkerInfo {
    return {
        parkerId: p.parkerId,
        operatorId: p.operatorId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        createdWhen: createInstant( p.createdWhen ),
    }
}

export function parseRateProgramData( r: RateProgramDataApi ): RateProgramData {
    return {
        facilityId: r.facilityId,
        name: r.name,
        start: createLocalDateTime( r.start ),
        end: r.end != null ? createLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? createLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? createLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? createLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? createLocalTime( r.outBefore ) : null,
        entries: r.entries.map( el => parseRateEntryData( el ) ),
    }
}

export function parseRateEntryData( r: RateEntryDataApi ): RateEntryData {
    return {
        upTo: Duration.ofMinutes( r.upTo ),
        rate: cents( r.rate ),
    }
}

export function parseSubscriberInfo( s: SubscriberInfoApi ): SubscriberInfo {
    return {
        parkerId: s.parkerId,
        name: s.name,
        note: s.note,
        emailAddress: s.emailAddress,
        phoneNumber: s.phoneNumber != null ? s.phoneNumber : null,
        quantity: s.quantity,
    }
}

export function parseSubscriptionUpdateReq( s: SubscriptionUpdateReqApi ): SubscriptionUpdateReq {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: createLocalDate( s.start ),
        end: s.end != null ? createLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
    }
}

export function parseSubEntry( s: SubEntryApi ): SubEntry {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planId: s.planId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: createLocalDate( s.start ),
        end: s.end != null ? createLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
        createdWhen: createInstant( s.createdWhen ),
    }
}

export function parseSubEntryForEdit( s: SubEntryForEditApi ): SubEntryForEdit {
    return {
        prices: s.prices.map( el => parsePlanPriceInfo( el ) ),
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planId: s.planId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        status: s.status,
        start: createLocalDate( s.start ),
        end: s.end != null ? createLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        isLateFeeEnabled: s.isLateFeeEnabled,
        createdWhen: createInstant( s.createdWhen ),
    }
}

export function parseAccessPeriodInfo( a: AccessPeriodInfoApi ): AccessPeriodInfo {
    return {
        accessPeriodId: a.accessPeriodId,
        subscriptionEntryId: a.subscriptionEntryId,
        quantity: a.quantity,
        start: createLocalDate( a.start ),
        end: createLocalDate( a.end ),
        isDelivered: a.isDelivered,
        price: cents( a.price ),
    }
}

export function parseSubscriptionParker( s: SubscriptionParkerApi ): SubscriptionParker {
    return {
        parker: parseParkerInfo( s.parker ),
        quantity: s.quantity,
        vehicles: s.vehicles.map( el => parseVehicle( el ) ),
    }
}

export function parseParkerUpdateReq( p: ParkerUpdateReqApi ): ParkerUpdateReq {
    return {
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        note: p.note,
    }
}

export function parseSubListEntry( s: SubListEntryApi ): SubListEntry {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        status: s.status,
        quantity: s.quantity,
        unitPrice: s.unitPrice != null ? s.unitPrice : null,
        price: s.price != null ? s.price : null,
        plan: parsePlanInfoBasic( s.plan ),
        facility: parseFacilityInfo( s.facility ),
    }
}

export function parsePlanInfoBasic( p: PlanInfoBasicApi ): PlanInfoBasic {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        accessHours: p.accessHours,
    }
}

export function parseFacilitySub( f: FacilitySubApi ): FacilitySub {
    return {
        subscriptionId: f.subscriptionId,
        parkerId: f.parkerId,
        status: f.status,
        quantity: f.quantity,
        unitPrice: cents( f.unitPrice ),
        price: cents( f.price ),
        parker: parseParkerInfo( f.parker ),
        plan: parsePlanInfoBasic( f.plan ),
        facility: parseFacilityInfo( f.facility ),
    }
}

export function parseStaff( s: StaffApi ): Staff {
    return {
        staffId: s.staffId,
        role: s.role,
        operatorId: s.operatorId,
        name: s.name,
        emailAddress: s.emailAddress,
        phoneNumber: s.phoneNumber,
    }
}

export function parseDeleteConfirm( d: DeleteConfirmApi ): DeleteConfirm {
    return {
        deleteCode: d.deleteCode,
    }
}

export function parseChangeEmailReq( c: ChangeEmailReqApi ): ChangeEmailReq {
    return {
        currentPassword: c.currentPassword,
        newEmailAddress: c.newEmailAddress,
    }
}

export function parseVehicleDetails( v: VehicleDetailsApi ): VehicleDetails {
    return {
        vehicleId: v.vehicleId,
        make: v.make,
        model: v.model,
        color: v.color,
        stateCode: v.stateCode,
        licensePlateNumber: v.licensePlateNumber,
        links: v.links.map( el => parseVehicleLink( el ) ),
    }
}

export function parseVehicleLink( v: VehicleLinkApi ): VehicleLink {
    return {
        type: v.type,
        reservationId: v.reservationId != null ? v.reservationId : null,
        subscriptionId: v.subscriptionId != null ? v.subscriptionId : null,
        reservation: v.reservation != null ? parseReservation( v.reservation ) : null,
        subscription: parseSubListEntry( v.subscription ),
    }
}

export function parseFacilityInvoice( f: FacilityInvoiceApi ): FacilityInvoice {
    return {
        invoiceId: f.invoiceId,
        invoiceNumber: f.invoiceNumber,
        orderId: f.orderId,
        createdWhen: createInstant( f.createdWhen ),
        dueWhen: createLocalDate( f.dueWhen ),
        openingBalance: cents( f.openingBalance ),
        amountDue: cents( f.amountDue ),
        endingBalance: cents( f.endingBalance ),
        emailSentWhen: f.emailSentWhen != null ? createInstant( f.emailSentWhen ) : null,
        order: parseOrderInfo( f.order ),
        parker: parseParkerInfo( f.parker ),
    }
}


