import { Col, Container, Nav, Row } from "react-bootstrap";
import { Button } from "./Controls/Button";
import { FacilityListEntry } from "./Controls/FacilityListEntry";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { InfoWindow, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import { useEffect, useState } from "react";
import { PlaceInput } from "./Controls/PlaceInput";
import { Facility } from "./Data/ApiTypes";
import { Hyperlink } from "./Controls/Hyperlink";
import { Speedometer2 } from "react-bootstrap-icons";

interface MapHandlerProps {
    place: google.maps.places.PlaceResult | null;
}

const MapHandler = ( { place }: MapHandlerProps ) => {
    const map = useMap();

    useEffect( () => {
        if( !map || !place ) {
            return;
        }
        if( place.geometry?.viewport ) {
            map.fitBounds( place.geometry.viewport );
        }
    }, [map, place] );

    return null;
};

export function FacilityListPage() {
    const nav = useNavigate();
    const facilities = useLoaderData() as Facility[];
    const [selectedFacility, setSelectedFacility] = useState<Facility>();
    const [place, setPlace] = useState<google.maps.places.PlaceResult | null>( null );
    const navToFacility = ( facilityId: number ) => { return nav( `/facility/${facilityId}` ); };
    return <Container fluid className="fullheight">
        <Row className="fullheight">
            <Col md={7} lg={6} xl={5} xxl={4} className="pt-2 scroll-y fullheight">
                {/* <Nav.Link as={Link} className="w-100 p-4 mb-2 rounded-4 text-center text-primary text-decoration-underline" to={"/operator"}>
                    <Speedometer2 height="25" width="25" className="me-2" />Back to Operator Dashboard
                </Nav.Link> */}
                <PlaceInput
                    label="Find a Facility"
                    onPlaceSelect={ ( pl ) => setPlace( pl ) }
                    explanation="Type in the address or name of the facility you want to find." />
                <Button onClick={ () => nav( "/facility/create" )} className="my-3 d-block w-100">
                    Add New Parking Facility
                </Button>
                {facilities.map( f =>
                    <FacilityListEntry key={f.facilityId}
                                       facility={f}
                                       className="border"
                                       onClick={ () => navToFacility( f.facilityId ) }  /> )}
            </Col>
            <Col className="d-none d-md-block fullheight">
                <Map defaultCenter={{ lat: 39.299236, lng: -76.609383 }}
                    defaultZoom={13}
                    mapId="6d064567c51cdbc9">
                    {facilities.map( f => {
                        function clickFacility( ev: any ) {
                            setSelectedFacility( f );
                        }
                        if( f === selectedFacility ) {
                            return <InfoWindow key={f.facilityId}
                                               onClose={() => setSelectedFacility( undefined )}
                                               zIndex={1000}
                                               headerDisabled
                                               position={selectedFacility.coordinates}>
                                <div className="p-1" style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "0.5em" }}>
                                    <div className="fw-bold fs-6 grid-col-1">
                                        {selectedFacility.name}
                                    </div>
                                    <div className="grid-col-1 text-start text-primary text-decoration-underline">
                                        <Hyperlink onClick={ () => navToFacility( selectedFacility.facilityId ) }>
                                            Manage Facility
                                        </Hyperlink>
                                    </div>
                                </div>
                            </InfoWindow>
                        }
                        const icon = {
                            url:         "/map-pin-expressway.svg",
                            labelOrigin: new google.maps.Point( 22, 18 ),
                            scaledSize:  new google.maps.Size(  45, 45 )
                        };
                        return <Marker
                            key={f.facilityId}
                            icon={icon}
                            position={f.coordinates}
                            onClick={clickFacility}
                            zIndex={f.facilityId}
                            label={{
                                fontFamily: "expressway",
                                fontSize:   "1.75em",
                                fontWeight: "900",
                                color:      "black",
                                text:       "P"
                            }}>
                        </Marker>;
                    } )}
                    <MapHandler place={place} />
                </Map>
            </Col>
        </Row>
    </Container>;
}