import { Form, ListGroup } from "react-bootstrap";
import { Facility, SearchResult } from "../Data/ApiTypes";
import { EntityType, SearchResultApi } from "../Data/ApiTransport";
import { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../Data/Api";
import { parseSearchResult } from "../Data/ApiParse";
import { ApiUrl } from "../Data/ApiUrl";
import { processResult } from "../Data/Result";

interface FacilityListSearchProps {
    className?:   string;
    facility?:    Facility;
    placeholder?: string;
}

function useDebounce<T extends ( ...args: any[] ) => any>(
    callback: T,
    delay:    number
) {
    const timeoutRef = useRef<NodeJS.Timeout>();
    useEffect( () => {
        return () => {
            if( timeoutRef.current ) {
                clearTimeout( timeoutRef.current );
            }
        };
    }, [] );

    return useCallback( ( ...args: Parameters<T> ) => {
        if( timeoutRef.current ) {
            clearTimeout( timeoutRef.current );
        }

        timeoutRef.current = setTimeout( () => {
            callback( ...args );
        }, delay );
    }, [callback, delay] );
}

export const FacilityListSearch = ( {
    className   = "",
    facility,
    placeholder = "Search..."
}: FacilityListSearchProps ) => {

    const nav = useNavigate();
    const [search,  setSearch]  = useState<string>( "" );
    const [results, setResults] = useState<SearchResult[]>( [] );
    const [sel,     setSel]     = useState<number>( -1 );
    const abortControllerRef = useRef<AbortController | null>( null );

    const performSearch = useCallback( async ( searchTerm: string, facilityId: number ) => {
        // cancel any pending requests
        if( abortControllerRef.current ) {
            abortControllerRef.current.abort();
        }

        // create new abortcontroller for this request
        const controller = new AbortController();
        abortControllerRef.current = controller;
        try {
            const res = await api<SearchResultApi[]>(
                ApiUrl.facilitySearch( facilityId, searchTerm ), {
                    method: "GET",
                    credentials: "include",
                    signal: controller.signal }
            );
            processResult( res,
                val => setResults( val.map( parseSearchResult ) ),
                ( err: any ) => {
                    console.info( "aborted" );
                    if( err.name !== 'AbortError' ) {
                        setResults( [] );
                    }
                }
            );
        } catch( error: any ) {
            console.error( error );
        }
    }, [] );

    const debSearch = useDebounce( performSearch, 300 );

    useEffect( () => {
        return () => {
            if( abortControllerRef.current ) {
                abortControllerRef.current.abort();
            }
        };
    }, [] );

    useEffect( () => {
        if( !facility || !search ) {
            setResults( [] );
            return;
        }
        const facilityId = facility.facilityId;
        debSearch( search, facilityId );
    }, [search, facility, debSearch] );

    function onSelect( res: SearchResult ) {
        if( !facility || !search ) {
            return;
        }
        try {
            const facilityId = facility.facilityId;
            switch( res.type ) {
                case EntityType.Parker:
                    nav( `/facility/${facilityId}/parker/${res.id}` );
                    return;
                case EntityType.Staff:
                    alert( "Nav to staff not implemented" );
                    return;
                case EntityType.Vehicle:
                    nav( `/facility/${facilityId}/parker/${res.parkerId}/vehicle/${res.id}` );
                    return;
                case EntityType.Order:
                    nav( `/facility/${facilityId}/parker/${res.parkerId}/order/${res.id}` );
                    return;
                case EntityType.Invoice:
                    nav( `/facility/${facilityId}/parker/${res.parkerId}/invoice/${res.id}` );
                    return;
                case EntityType.Payment:
                    nav( `/facility/${facilityId}/parker/${res.parkerId}/payment/${res.id}` );
                    return;
                default:
                    throw new Error( "Entity Type Invalid" );
            }
        }
        finally {
            setResults( [] );
        }
    }

    function showSearchResultType( type: EntityType ): import( "react" ).ReactNode {
        switch( type ) {
            case EntityType.Parker:  return "Parker";
            case EntityType.Staff:   return "Staff";
            case EntityType.Order:   return "Order";
            case EntityType.Invoice: return "Invoice";
            case EntityType.Payment: return "Payment";
            case EntityType.Vehicle: return "Vehicle";
            default:
                throw new Error( "Entity Type Invalid" );
        }
    }

    return (
        <div className={`position-relative ${className}`}>
            <div className="position-relative">
                <Form.Control
                    className="border-dark shadow-sm border-3"
                    type="text"
                    value={search}
                    onKeyDown={ e => {
                        if( e.key === "ArrowUp" ) {
                            setSel( Math.max( 0, sel - 1 ) );
                            return;
                        }
                        if( e.key === "ArrowDown" ) {
                            setSel( Math.min( results.length - 1, sel + 1 ) );
                        }
                        if( e.key === "Enter" ) {
                            onSelect( results[sel] );
                        }
                        if( e.key === "Escape" ) {
                            setSel( -1 );
                            setResults( [] );
                        }
                    } }
                    onChange={( e ) => setSearch( e.target.value )}
                    placeholder={placeholder}
                />
            </div>
            {search && results.length > 0 && (
                <ListGroup
                    className="position-absolute w-100 border-primary rounded-3 pe-2 pe-sm-3"
                    style={{ zIndex: 1000, maxHeight: "300px", overflowY: "auto", top: "100%" }}>
                    {results.map( ( result, i ) => (
                        <ListGroup.Item
                            key={result.searchResultId}
                            onClick={() => onSelect?.( result )}
                            className={`d-flex align-items-center ${ i === sel ? "bg-primary text-white" : "" }`}
                            action>
                            <div className="d-flex align-items-center gap-2">
                                <div className="fw-bold">
                                    {showSearchResultType( result.type )}
                                </div> | <div>{result.name}</div>
                            </div>
                        </ListGroup.Item>
                    ) )}
                </ListGroup>
            )}
        </div>
    );
};